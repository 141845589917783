import { gql, useApolloClient } from '@apollo/client';
import { useContext, useEffect, useRef, useState } from 'react';
import Skeleton from 'react-loading-skeleton';
import { withRouter } from 'react-router-dom';

import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
} from '@material-ui/core';

import { useRequiredContext } from '../../../common/hooks-util';
import AutoComplete from '../../../components/auto-complete';
import DialogWrapper from '../../../components/dialog-wrapper';
import HighlightedFieldset from '../../../components/highlighted-fieldset';
import QueryWrapper from '../../../components/query-wrapper';
import { AuthContext } from '../../../contexts/auth-context';
import ProjectContext from '../../../contexts/project-context';
import Documents from './documents';

const GET_PROJECTS = gql`
  query Projects {
    projects(first: 2000) {
      edges {
        node {
          id
          referenceNumber
          name
        }
      }
    }
  }
`;

function DocumentsHandler({
  data,
  docIds,
  open,
  doneCallback,
  onClose,
  refetching,
  isCopy,
}) {
  const { accessTokenRef } = useRequiredContext(AuthContext);

  const project = useContext(ProjectContext);
  const projectAnchorEl = useRef(null);
  const [projectReference, setProjectReference] = useState(null);
  const [projectName, setProjectName] = useState(null);
  const [dstPath, setDstPath] = useState(null);
  const [loading, setLoading] = useState(false);

  const client = useApolloClient();
  useEffect(() => {
    const initialFileSettings = () => {
      !projectReference && setProjectReference(project.project.referenceNumber);
      !projectName && setProjectName(project.project.name);
    };
    initialFileSettings();
  }, [projectReference, project, projectName]);

  const handleChange = (e) => {
    setProjectReference(e.target.value);
    setProjectName(e.target.secondaryValue);
  };

  const handlePathChange = (path) => {
    setDstPath(path);
  };

  const moveFile = async (docIds, internalMove) => {
    const moveUri = `${process.env.REACT_APP_API_URI}document/${
      internalMove ? 'updatePathes' : 'moveFiles'
    }?auth=${accessTokenRef.current}`;
    const data = new FormData();
    data.append('docIds', JSON.stringify(docIds));
    data.append('projectReference', projectReference);
    data.append('originalProjectReference', project.project.referenceNumber);
    if (!!dstPath) data.append('dstPath', dstPath);

    const response = await fetch(moveUri, {
      method: 'POST',
      body: data,
    });

    if (response.ok) doneCallback && doneCallback();
    else {
      doneCallback &&
        doneCallback(
          response.status === 400
            ? await response.text()
            : 'server internal error'
        );
    }
  };

  const copyFile = async (docIds, copyFilenames, referenceNumber) => {
    const copyUri = `${process.env.REACT_APP_API_URI}document/copyFiles?auth=${accessTokenRef.current}`;

    const data = new FormData();
    data.append('docIds', JSON.stringify(docIds));
    data.append('projectReference', referenceNumber);
    data.append('fileNames', JSON.stringify(copyFilenames));
    if (!!dstPath) data.append('dstPath', dstPath);

    const response = await fetch(copyUri, {
      method: 'POST',
      body: data,
    });

    if (response.ok) doneCallback && doneCallback();
    else {
      doneCallback &&
        doneCallback(
          response.status === 400
            ? await response.text()
            : 'server internal error'
        );
    }
  };

  const handleSubmit = async (docIds) => {
    try {
      setLoading(true);
      if (isCopy) {
        const copyNames = docIds
          .map((id) => data.find((d) => d.node.id === id).node)
          .map((d) => {
            return {
              key: d.id,
              value:
                d.filename !== 'Bilder'
                  ? `Kopia_${d.filename}`
                  : `Kopia_Album: ${d.tags || 'Ingen titel på album'}`,
            };
          });
        await copyFile(docIds, copyNames, projectReference);
      } else {
        const internalMove =
          projectReference === project.project.referenceNumber;
        await moveFile(docIds, internalMove);
      }
      refetching();
    } finally {
      setLoading(false);
    }
  };

  return (
    <QueryWrapper query={GET_PROJECTS} errorMessageProps={{ paper: true }}>
      {({ loading: projectsLoading, data }) => (
        <DialogWrapper
          open={open}
          onClose={onClose}
          loading={projectsLoading || loading}
          aria-labelledby='gallery-dialog'
        >
          <DialogTitle>{isCopy ? 'Kopiera' : 'Flytta'} fil</DialogTitle>
          <HighlightedFieldset
            mode='info'
            icon='help'
            title={isCopy ? 'Kopiera filen' : 'Flytta filen'}
            text={`${
              isCopy ? 'Kopiera' : 'Flytta'
            } en fil genom att navigera i filstrukturen nedan till den nya önskade platsen. Filen kan ${
              isCopy ? 'kopieras' : 'flyttas'
            } till ett annat projekt genom att ändra valt projekt nedan. Sök på namn eller projektnummer`}
          />
          <DialogContent>
            <Grid container spacing={2} style={{ paddingTop: 15, height: 390 }}>
              <Grid item xs={12}>
                <div ref={projectAnchorEl}>
                  <div style={{ marginTop: 10 }}>
                    <div ref={projectAnchorEl}>
                      {loading || projectsLoading ? (
                        <Skeleton />
                      ) : (
                        <AutoComplete
                          id='project-autocomplete'
                          label='Sök och välj projekt'
                          placeholder='Projektnamn'
                          items={data.projects.edges.map((p) => ({
                            id: p.node.referenceNumber,
                            name: `${p.node.name} - ${p.node.referenceNumber}`,
                            referenceNumber: p.node.referenceNumber,
                          }))}
                          name='project'
                          color='primary'
                          initialSelectedItem={`${projectName} - ${projectReference}`}
                          onChange={(e) => handleChange(e)}
                          anchorEl={projectAnchorEl.current}
                        />
                      )}
                    </div>
                  </div>
                </div>
              </Grid>
              <Grid item xs={12}>
                <Documents
                  match={{
                    ...{},
                    params: {
                      referenceNumber: project.project.referenceNumber,
                    },
                  }}
                  isMoveOrCopyView
                  handlePathChange={handlePathChange}
                  client={client}
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button onClick={onClose}>Avbryt</Button>
            <Button
              onClick={() => handleSubmit(docIds)}
              color='primary'
              variant='contained'
              disabled={loading || !dstPath}
            >
              Spara
            </Button>
          </DialogActions>
        </DialogWrapper>
      )}
    </QueryWrapper>
  );
}

export default withRouter(DocumentsHandler);
