import { createContext } from 'react';

interface ProjectProps {
  id: number;
  referenceNumber: string;
  name: string;
  manageBlasts: unknown;
  isPropertyOwner: boolean;
}

interface ProjectContextProps {
  project: ProjectProps;
  setProject: (newProject: ProjectProps) => void;
}

const ProjectContext = createContext<ProjectContextProps | undefined>(
  undefined
);

export default ProjectContext;
