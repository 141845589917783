import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router';

import {
  AppBar,
  Button,
  Hidden,
  IconButton,
  Toolbar,
  Typography,
  withStyles,
} from '@material-ui/core';
import {
  Close as CloseIcon,
  Menu as MenuIcon,
  Search as SearchIcon,
} from '@material-ui/icons';

import { useRequiredContext } from '../../common/hooks-util';
import { AuthContext } from '../../contexts/auth-context';
import ProjectContext from '../../contexts/project-context';
import { UserContext } from '../../contexts/user-context';
import globalRoutes from '../../pages/routes';
import { closeSearchResults } from '../search-results';
import AppSwitcher from './app-switcher';
import DaemonStatus from './daemon-status';
import FaqButton from './faq-button';
import ProfileMenu from './profile-menu';
import Search from './search';
import styles from './styles';

const showRoute = (route, role) =>
  !route.hidden &&
  !route.hasMenu &&
  route.allowedRoles?.includes(role) !== false;

const Header = ({
  classes,
  drawerOpen,
  setDrawerOpen,
  currentRoute,
  client,
}) => {
  const user = useContext(UserContext);
  const projectContext = useContext(ProjectContext);

  const { logout } = useRequiredContext(AuthContext);

  const history = useHistory();

  const [isMobileSearch, setIsMobileSearch] = useState(false);

  const headTitle = useMemo(
    () =>
      projectContext.project == null
        ? ''
        : `${projectContext.project.referenceNumber} ${projectContext.project.name} ${projectContext.project.customer.id} ${projectContext.project.customer.name}`,
    [projectContext.project]
  );

  useEffect(() => {
    // Close search on navigation
    const unregister = history.listen(() => {
      setIsMobileSearch(false);
    });

    return unregister;
  }, [history]);

  const isContactPerson = useMemo(
    () => user?.role === 'ContactPerson',
    [user?.role]
  );

  if (!user?.role) {
    return null;
  }

  return (
    <AppBar position='static' style={{ flexShrink: 0 }}>
      {isMobileSearch && (
        <Toolbar>
          <div className={classes.search}>
            <div className={classes.searchIcon}>
              <SearchIcon />
            </div>
            <Search autoFocus />
          </div>
          <IconButton
            onClick={() => {
              setIsMobileSearch(!isMobileSearch);
              closeSearchResults(client);
            }}
            style={{ marginRight: -16 }}
          >
            <CloseIcon />
          </IconButton>
        </Toolbar>
      )}

      <Toolbar style={{ display: isMobileSearch ? 'none' : 'flex' }}>
        <IconButton
          color='inherit'
          aria-label='Open menu'
          onClick={() => {
            setDrawerOpen(!drawerOpen);
          }}
          className={classes.menuButton}
        >
          <MenuIcon />
        </IconButton>
        {currentRoute && currentRoute.logo ? (
          <Typography
            component='h1'
            variant='h4'
            color='inherit'
            noWrap
            className={classes.title}
          >
            {currentRoute.logo}{' '}
          </Typography>
        ) : (
          <Typography
            component='h1'
            variant='h6'
            color='inherit'
            noWrap
            className={classes.title}
          >
            {currentRoute && currentRoute.name}{' '}
            {currentRoute.path.includes('/project') ? headTitle : ''}
          </Typography>
        )}
        <div className={classes.grow} />
        <div className={classes.globalLinks}>
          {globalRoutes.map(
            (r) =>
              showRoute(r, user.role) && (
                <Button
                  key={r.path}
                  style={{ color: '#f9f9f9' }}
                  onClick={() => history.push(r.path)}
                >
                  {r.name}
                </Button>
              )
          )}
        </div>
        {!isContactPerson && (
          <Hidden smDown>
            <DaemonStatus />
          </Hidden>
        )}

        {!isContactPerson && (
          <Hidden mdUp>
            <IconButton onClick={() => setIsMobileSearch(!isMobileSearch)}>
              <SearchIcon style={{ color: '#f9f9f9' }} />
            </IconButton>
          </Hidden>
        )}

        <ProfileMenu user={user} handleLogout={logout} />
        {!isContactPerson && (
          <Hidden smDown>
            <div className={classes.search}>
              <div className={classes.searchIcon}>
                <SearchIcon />
              </div>
              <Search />
            </div>
          </Hidden>
        )}

        <FaqButton />

        {!isContactPerson && <AppSwitcher />}
      </Toolbar>
    </AppBar>
  );
};
export default withStyles(styles)(Header);
