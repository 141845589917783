import { gql } from '@apollo/client';
import React, { Fragment, useImperativeHandle } from 'react';

import {
  Button,
  Grid,
  Hidden,
  LinearProgress,
  List,
  ListItem,
  ListItemText,
  Menu,
  MenuItem,
  Paper,
  Popover,
  TextField,
  Typography,
  withStyles,
} from '@material-ui/core';
import { alpha } from '@material-ui/core/styles';
import { ChevronRight } from '@material-ui/icons';

import DocumentListItem from '../../../components/document-list-item';
import ErrorMessageDialog from '../../../components/error-message-dialog';
import QueryWrapper from '../../../components/query-wrapper';
import SearchField from '../../../components/search-field';
import { AuthContext } from '../../../contexts/auth-context';
import { DaemonConsumer } from '../../../contexts/daemon-context';
import { UploadConsumer } from '../../../contexts/upload-context';
import DocumentBin from './document-bin';
import DeleteDocumentsConfirm from './documents-delete-confirm';
import DocumentsHandler from './documents-handler';
import DocumentFolder from './folder';

export const styles = (theme) => ({
  header: { display: 'flex' },
  headerComponent: { flex: 'auto' },
  nested: {
    marginLeft: theme.spacing(4),
    borderLeft: '2px solid #eee',
  },
  folderHover: {
    backgroundColor: alpha(theme.palette.primary.light, 0.2),
  },
  emptyFolder: {
    color: theme.palette.text.disabled,
  },
  emphasis: { fontStyle: 'italic' },
  button: { marginTop: '16px', float: 'right' },
  breadcrumbs: {
    color: 'rgba(0,0,0,0.54)',
    marginBottom: '10px',
    fontFamily: '"Helvetica", "Arial", sans-serif',
    fontSize: '12px',
  },
  breadcrumbContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-end',
  },
  breadcrumbIcon: {
    width: '15px',
    height: '15px',
    verticalAlign: 'bottom',
  },
  breadcrumbLink: {
    textDecoration: 'underline',
    cursor: 'pointer',
  },
  zipDownloadPopover: {
    padding: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
  },
  zipDownloadButtonsWrapper: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'baseline',
  },
});

export const GET_FOLDERS = gql`
  query Folders {
    documentFolders {
      root {
        name
        allowUploads
        defaultVisible
        children {
          name
          allowUploads
          defaultVisible
          children {
            name
            allowUploads
            defaultVisible
            children {
              name
              allowUploads
              defaultVisible
            }
          }
        }
      }
    }
  }
`;

export const GET_DOCUMENTS = gql`
  query Documents($referenceNumber: String, $includeInactive: Boolean) {
    project(referenceNumber: $referenceNumber) {
      id
      name
      referenceNumber
      documents(includeInactive: $includeInactive) {
        edges {
          node {
            id
            filename
            mime
            path
            tags
            modifiedAt
            modifiedBy
            isActive
            projectReference
          }
        }
      }
    }
  }
`;

const UPDATE_DOCUMENT_STATUS = gql`
  mutation UpdateDocumentStatus($id: Int!, $isActive: Boolean!) {
    updateDocumentStatus(id: $id, isActive: $isActive)
  }
`;

let localRemoveDaemonSaveListener;
const DOCUMENTS_SORT_ORDER = 'DocumentsSelectedSortOrder';

class Documents extends React.Component {
  state = {
    openDoc: null,
    uploadProgress: null,
    defaultFolders: [],
    searchTerm: '',
    binOpen: false,
    path: '',
    selectedDocuments: [],
    showCheckboxes: false,
    sortMenuOpen: false,
    sortBy: localStorage.getItem(DOCUMENTS_SORT_ORDER) || 'date',
    anchorZipEl: null,
    zipFilename: '',
    openDocHandler: false,
    isCopy: false,
    isDeleting: false,
    hasUploadError: false,
    hasFileOperationError: false,
    fileOperationError: '',
  };
  folderView = React.createRef();

  async componentDidMount() {
    const client = this.props.client;
    const result = await client?.query({
      query: GET_FOLDERS,
    });

    this.setState({ defaultFolders: result?.data?.documentFolders.root });
  }

  componentWillUnmount() {
    localRemoveDaemonSaveListener('documents');
  }

  handleToggleBin = () => {
    this.setState({ binOpen: !this.state.binOpen });
  };

  removeDocument = async (refetch, id) => {
    await this.updateDocumentStatus(refetch, id, false);
  };

  deleteSelectedDocuments = async (refetch) => {
    for (let i = 0; i < this.state.selectedDocuments.length; i++) {
      const id = this.state.selectedDocuments[i];
      await this.updateDocumentStatus(undefined, id, false);
    }
    this.setState({ selectedDocuments: [], isDeleting: false });
    refetch();
  };

  updateDocumentStatus = async (refetch, id, isActive) => {
    await this.props.client.mutate({
      mutation: UPDATE_DOCUMENT_STATUS,
      variables: {
        id,
        isActive: isActive,
      },
    });
    if (refetch) refetch();
  };

  handleOpenItem = (subpath) => {
    this.setState({ path: subpath });
    this.props.handlePathChange && this.props.handlePathChange(subpath);
  };

  handleBreadcrumbClick = (breadcrumb) => {
    if (this.state.path) {
      let previousPath = this.state.path.split('\\').filter((p) => p !== '');
      let i = previousPath.indexOf(breadcrumb);
      let newPath =
        i >= 0
          ? `\\${previousPath.filter((_, index) => index <= i).join('\\')}`
          : '';
      this.setState({ path: newPath });
    }
  };

  handleSelectDocument = (id) => {
    if (this.state.selectedDocuments.find((x) => x === id)) {
      this.setState({
        selectedDocuments: this.state.selectedDocuments.filter((x) => x !== id),
      });
      return;
    }
    this.setState({ selectedDocuments: [...this.state.selectedDocuments, id] });
  };

  handleZipDownloadFilename = (e) => {
    this.setState({ zipFilename: e.target.value });
  };

  handleZipDownloadPopover = (e) => {
    this.setState({ anchorZipEl: e.currentTarget });
  };

  handleZipDownloadCloseAndClearSelected = () => {
    this.setState({
      showCheckboxes: !this.state.showCheckboxes,
      selectedDocuments: [],
      zipFilename: '',
      anchorZipEl: null,
    });
  };

  handleSelectAll = () => {
    this.setState({
      selectedDocuments: this.folderView.current.docIds(),
    });
  };

  handleDownloadZip = async (accessToken) => {
    const fileName = encodeURIComponent(this.state.zipFilename);
    const url = `${process.env.REACT_APP_API_URI}document/fileszip?filename=${fileName}&auth=${accessToken}`;
    const docIds = this.state.selectedDocuments;
    const data = new FormData();
    data.append('docIds', JSON.stringify(docIds));

    try {
      const response = await fetch(url, {
        method: 'POST',
        body: data,
      });

      const blobObject = await response.blob();

      const blob = window.URL.createObjectURL(blobObject);
      const anchor = document.createElement('a');
      anchor.style.display = 'none';
      anchor.href = blob;
      anchor.download = fileName;
      document.body.appendChild(anchor);
      anchor.click();
      window.URL.revokeObjectURL(blob);
    } catch (error) {
      console.log(error);
    }
  };

  render() {
    const {
      match: { params },
      classes,
      isMoveOrCopyView,
    } = this.props;
    const {
      selectedDocuments,
      anchorZipEl,
      zipFilename,
      openDocHandler,
      isCopy,
      isDeleting,
    } = this.state;
    const openZipDownload = Boolean(anchorZipEl);
    return (
      <AuthContext.Consumer>
        {({ accessTokenRef }) => (
          <DaemonConsumer>
            {({ addDaemonSaveListener, removeDaemonSaveListener }) => {
              localRemoveDaemonSaveListener = removeDaemonSaveListener;

              return (
                <>
                  <Grid
                    container
                    spacing={3}
                    style={
                      isMoveOrCopyView
                        ? { justifyContent: 'center' }
                        : { justifyContent: 'none' }
                    }
                  >
                    <Grid item xs={12} sm={12} md={9}>
                      <div className={classes.header}>
                        {isMoveOrCopyView ? (
                          <Typography
                            variant='h6'
                            gutterBottom
                            color='textSecondary'
                            className={classes.headerComponent}
                          >
                            Välj mapp
                          </Typography>
                        ) : (
                          <>
                            <Typography
                              variant='h6'
                              gutterBottom
                              color='textSecondary'
                              className={classes.headerComponent}
                            >
                              Dokument
                            </Typography>
                            <SearchField
                              searchTerm={this.state.searchTerm}
                              onChange={(v) => this.setState({ searchTerm: v })}
                              onClear={() => this.setState({ searchTerm: '' })}
                            />
                          </>
                        )}
                      </div>
                      <div className={classes.breadcrumbContainer}>
                        <div className={classes.breadcrumbs}>
                          <Breadcrumbs
                            path={this.state.path}
                            classes={classes}
                            handleBreadcrumbClick={this.handleBreadcrumbClick}
                          />
                        </div>
                        {!isMoveOrCopyView ? (
                          <div style={{ marginBottom: 8 }}>
                            {selectedDocuments.length > 0 && (
                              <>
                                <Button
                                  style={{ marginRight: 8 }}
                                  size='small'
                                  onClick={() => {
                                    this.setState({
                                      openDocHandler: true,
                                      isCopy: true,
                                    });
                                  }}
                                >
                                  SKAPA KOPIA
                                </Button>
                                <Button
                                  style={{ marginRight: 8 }}
                                  size='small'
                                  onClick={() => {
                                    this.setState({ isDeleting: true });
                                  }}
                                >
                                  TA BORT
                                </Button>
                                <Button
                                  style={{ marginRight: 8 }}
                                  size='small'
                                  onClick={() =>
                                    this.setState({
                                      openDocHandler: true,
                                      isCopy: false,
                                    })
                                  }
                                >
                                  FLYTTA
                                </Button>
                                <Button
                                  style={{ marginRight: 8 }}
                                  size='small'
                                  onClick={this.handleZipDownloadPopover}
                                >
                                  {`Ladda ner ${selectedDocuments.length} filer`}
                                </Button>
                              </>
                            )}

                            <Popover
                              open={openZipDownload}
                              anchorEl={anchorZipEl}
                              onClose={
                                this.handleZipDownloadCloseAndClearSelected
                              }
                              anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'center',
                              }}
                              transformOrigin={{
                                vertical: 'top',
                                horizontal: 'center',
                              }}
                            >
                              <div className={classes.zipDownloadPopover}>
                                <Typography
                                  className={classes.headerComponent}
                                  variant='h6'
                                  align='center'
                                  gutterBottom
                                >
                                  Namn på Zip-filen?
                                </Typography>
                                <Typography
                                  className={classes.headerComponent}
                                  variant='body2'
                                  color='textSecondary'
                                  paragraph
                                >
                                  Om du inte väljer ett filnamn kommer filen{' '}
                                  <br />
                                  döpas till projektets projektnummer.
                                </Typography>
                                <TextField
                                  label='Filnamn'
                                  value={zipFilename}
                                  onChange={(event) =>
                                    this.setState({
                                      zipFilename: event.target.value,
                                    })
                                  }
                                  fullWidth
                                />
                                <div
                                  className={classes.zipDownloadButtonsWrapper}
                                >
                                  <Button
                                    variant='contained'
                                    size='small'
                                    onClick={
                                      this
                                        .handleZipDownloadCloseAndClearSelected
                                    }
                                  >
                                    Avbryt
                                  </Button>
                                  <Button
                                    style={{ marginLeft: 8, marginTop: 10 }}
                                    color='primary'
                                    variant='contained'
                                    size='small'
                                    component='a'
                                    onClick={() => {
                                      this.handleDownloadZip(
                                        accessTokenRef.current
                                      );
                                      setTimeout(
                                        () =>
                                          this.handleZipDownloadCloseAndClearSelected(),
                                        1000
                                      );
                                    }}
                                  >
                                    Ladda ner
                                  </Button>
                                </div>
                              </div>
                            </Popover>
                            <Hidden smDown>
                              {this.state.showCheckboxes && (
                                <Button
                                  style={{ marginRight: 8 }}
                                  size='small'
                                  onClick={this.handleSelectAll}
                                >
                                  Välj Alla
                                </Button>
                              )}
                              <Button
                                variant={
                                  this.state.showCheckboxes
                                    ? 'contained'
                                    : 'text'
                                }
                                color={
                                  this.state.showCheckboxes
                                    ? 'primary'
                                    : 'default'
                                }
                                size='small'
                                onClick={
                                  this.handleZipDownloadCloseAndClearSelected
                                }
                              >
                                Välj flera
                              </Button>
                              <Button
                                variant='text'
                                color='default'
                                size='small'
                                ref={(node) => {
                                  this.anchorEl = node;
                                }}
                                onClick={() => {
                                  this.setState({
                                    sortMenuOpen: !this.state.sortMenuOpen,
                                  });
                                }}
                              >
                                Sortera
                              </Button>
                            </Hidden>
                          </div>
                        ) : (
                          ''
                        )}
                        <Menu
                          anchorEl={this.anchorEl}
                          open={this.state.sortMenuOpen}
                          onClose={() => this.setState({ sortMenuOpen: false })}
                        >
                          <MenuItem
                            selected={this.state.sortBy === 'name'}
                            onClick={() => {
                              this.setState({
                                sortBy: 'name',
                                sortMenuOpen: false,
                              });
                              localStorage.setItem(
                                DOCUMENTS_SORT_ORDER,
                                'name'
                              );
                            }}
                          >
                            Namnordning
                          </MenuItem>
                          <MenuItem
                            selected={this.state.sortBy === 'date'}
                            onClick={() => {
                              this.setState({
                                sortBy: 'date',
                                sortMenuOpen: false,
                              });
                              localStorage.setItem(
                                DOCUMENTS_SORT_ORDER,
                                'date'
                              );
                            }}
                          >
                            Datumordning
                          </MenuItem>
                        </Menu>
                      </div>

                      <QueryWrapper
                        query={GET_DOCUMENTS}
                        fetchPolicy='cache-and-network'
                        variables={{
                          referenceNumber: params.referenceNumber,
                          includeInactive: true,
                        }}
                        errorMessageProps={{ paper: true }}
                      >
                        {({ loading, data, refetch }) => {
                          addDaemonSaveListener('documents', refetch);
                          if (loading) return null;
                          const deletedDocuments =
                            data.project.documents.edges.filter(
                              (d) => !d.node.isActive
                            );
                          const documents = data.project.documents.edges
                            .filter((d) => d.node.isActive)
                            .sort((a, b) =>
                              a.node.filename.localeCompare(b.node.filename)
                            );

                          const self = this;

                          return (
                            <Paper style={{ paddingTop: 8, paddingBottom: 8 }}>
                              <DeleteDocumentsConfirm
                                isDeleting={isDeleting}
                                onConfirm={self.deleteSelectedDocuments.bind(
                                  self,
                                  refetch
                                )}
                                onCancel={() => {
                                  self.setState({ isDeleting: false });
                                }}
                              ></DeleteDocumentsConfirm>
                              {openDocHandler && (
                                <DocumentsHandler
                                  open
                                  client={this.props.client}
                                  data={documents}
                                  docIds={this.state.selectedDocuments}
                                  classes={classes}
                                  isCopy={isCopy}
                                  doneCallback={(error) =>
                                    this.setState({
                                      openDocHandler: false,
                                      isCopy: false,
                                      selectedDocuments: [],
                                      hasFileOperationError: error != null,
                                      fileOperationError: error,
                                    })
                                  }
                                  onClose={() =>
                                    this.setState({
                                      openDocHandler: false,
                                      isCopy: false,
                                    })
                                  }
                                  refetching={refetch}
                                />
                              )}
                              {this.state.uploadProgress && (
                                <LinearProgress
                                  variant={
                                    this.state.uploadProgress === 100
                                      ? 'indeterminate'
                                      : 'determinate'
                                  }
                                  value={this.state.uploadProgress}
                                />
                              )}
                              {this.state.searchTerm &&
                              this.state.searchTerm.length >= 3 ? (
                                <SearchResults
                                  searchResults={documents
                                    .filter(
                                      (d) =>
                                        d.node.filename
                                          .toLocaleLowerCase()
                                          .indexOf(
                                            this.state.searchTerm.toLocaleLowerCase()
                                          ) >= 0 ||
                                        (d.node.tags &&
                                          d.node.tags
                                            .toLocaleLowerCase()
                                            .indexOf(
                                              this.state.searchTerm.toLocaleLowerCase()
                                            ) >= 0)
                                    )
                                    .map((d) => d.node)}
                                  removeDocument={this.removeDocument.bind(
                                    this,
                                    refetch
                                  )}
                                  refetching={refetch}
                                />
                              ) : (
                                <UploadConsumer>
                                  {({ uploadFiles, isBaseDropzoneActive }) => {
                                    return (
                                      <FolderView
                                        ref={this.folderView}
                                        folders={self.state.defaultFolders}
                                        documents={documents}
                                        onFilesDropped={uploadFiles.bind(
                                          undefined,
                                          (error) =>
                                            self.setState({
                                              hasUploadError: error,
                                            }),
                                          refetch,
                                          data.project
                                        )}
                                        handleOpenItem={self.handleOpenItem.bind(
                                          this
                                        )}
                                        removeDocument={self.removeDocument.bind(
                                          this,
                                          refetch
                                        )}
                                        classes={classes}
                                        path={self.state.path}
                                        handleSelectDocument={
                                          self.handleSelectDocument
                                        }
                                        selectedDocuments={
                                          self.state.selectedDocuments
                                        }
                                        showCheckboxes={
                                          self.state.showCheckboxes
                                        }
                                        isMoveOrCopyView={isMoveOrCopyView}
                                        refetching={refetch}
                                        sortBy={this.state.sortBy}
                                        disabled={!isBaseDropzoneActive}
                                        handleError={(error) => {
                                          this.setState({
                                            hasFileOperationError:
                                              error != null,
                                            fileOperationError: error,
                                          });
                                        }}
                                      />
                                    );
                                  }}
                                </UploadConsumer>
                              )}
                              {!isMoveOrCopyView &&
                                deletedDocuments.length > 0 && (
                                  <>
                                    <Button
                                      variant='text'
                                      size='small'
                                      className={classes.button}
                                      onClick={() => this.handleToggleBin()}
                                    >
                                      Visa borttagna dokument
                                    </Button>
                                    <DocumentBin
                                      open={this.state.binOpen}
                                      handleClose={this.handleToggleBin}
                                      deletedDocuments={deletedDocuments}
                                      updateDocumentStatus={this.updateDocumentStatus.bind(
                                        this,
                                        refetch
                                      )}
                                    />
                                  </>
                                )}
                            </Paper>
                          );
                        }}
                      </QueryWrapper>
                    </Grid>
                  </Grid>
                  <ErrorMessageDialog
                    isModalOpen={
                      this.state.hasUploadError ||
                      this.state.hasFileOperationError
                    }
                    handleModalClose={() =>
                      this.setState({
                        hasUploadError: false,
                        hasFileOperationError: false,
                      })
                    }
                    message={
                      !this.state.hasFileOperationError
                        ? `Uppladdningen misslyckades. Vänligen kontrollera den uppladdade filen och ladda upp den igen om det behövs.`
                        : this.state.fileOperationError
                    }
                  />
                </>
              );
            }}
          </DaemonConsumer>
        )}
      </AuthContext.Consumer>
    );
  }

  clearSearch() {
    this.searchField.value = '';
    this.setState({ searchTerm: '' });
  }
}

const Breadcrumbs = ({ path, handleBreadcrumbClick, classes }) => {
  let crumbs = path && path.split('\\').filter((p) => p !== '');
  return (
    <>
      <span
        className={classes.breadcrumbLink}
        onClick={() => handleBreadcrumbClick('')}
      >
        Dokument
      </span>
      {crumbs &&
        crumbs.map((c, i) => {
          return c ? (
            <Fragment key={i}>
              <ChevronRight className={classes.breadcrumbIcon} />
              <span
                className={classes.breadcrumbLink}
                onClick={() => handleBreadcrumbClick(c)}
              >
                {c}
              </span>
            </Fragment>
          ) : null;
        })}
    </>
  );
};

const SearchResults = ({ searchResults, removeDocument, refetching }) => (
  <List dense disablePadding>
    {searchResults.length > 0 ? (
      searchResults.map((doc, i) => (
        <DocumentListItem
          key={doc.id}
          doc={doc}
          showPath
          removeDocument={removeDocument}
          isFirst={i === 0}
          refetching={refetching}
          handleError={(error) => {
            this.setState({
              hasFileOperationError: error != null,
              fileOperationError: error,
            });
          }}
        />
      ))
    ) : (
      <ListItem>
        <ListItemText primary='Inga sökträffar 🤔' />
      </ListItem>
    )}
  </List>
);

export const FolderView = React.forwardRef(
  (
    {
      folders,
      documents,
      classes,
      onFilesDropped,
      removeDocument,
      handleOpenItem,
      path,
      handleSelectDocument,
      selectedDocuments,
      showCheckboxes,
      isMoveOrCopyView,
      refetching,
      sortBy,
      disabled,
      handleError,
    },
    ref
  ) => {
    const buildSubfolders = (f) =>
      f.reduce((a, x, i) => {
        if (x.defaultVisible) {
          a[x.name] = {
            name: x.name,
            allowUploads: x.allowUploads,
            docs: [],
            subfolders: buildSubfolders(x.children),
            order: i,
          };
        }

        return a;
      }, {});

    const buildFolderPaths = (f) => {
      Object.keys(f).map((x) => {
        let parent = f[x];
        parent.path = `\\${x}`;
        parent.subfolders &&
          Object.keys(parent.subfolders).map((s) => {
            let child = parent.subfolders[s];
            child.path = `${parent.path}\\${s}`;
            child.parent = parent;
            child.subfolders &&
              Object.keys(child.subfolders).map((g) => {
                let grandchild = child.subfolders[g];
                grandchild.path = `${child.path}\\${g}`;
                grandchild.parent = child;
                return grandchild;
              });
            return child;
          });
        return parent;
      });
      return f;
    };

    const subfolders = folders ? buildSubfolders(folders) : {};
    buildFolderPaths(subfolders);

    const rootFolder = documents.reduce(
      (folders, e) => {
        const pathParts = e.node.path.split('\\');
        let f = folders;

        for (let i = 0; i < pathParts.length; i++) {
          const part = pathParts[i];
          const folder = f.subfolders[part];
          if (!folder) {
            f = f.subfolders[part] = {
              name: part,
              docs: [],
              subfolders: {},
            };
          } else {
            f = folder;
          }

          f.hasDocs = true;
        }

        f.docs.push(e.node);

        return folders;
      },
      { docs: [], subfolders }
    );

    let folder = rootFolder;
    if (path && path !== '') {
      let pathParts = path.split('\\').filter((p) => p !== '');
      if (pathParts.length === 1) {
        folder = rootFolder.subfolders[pathParts[0]];
      } else if (pathParts.length === 2) {
        folder = rootFolder.subfolders[pathParts[0]].subfolders[pathParts[1]];
      } else if (pathParts.length === 3) {
        folder =
          rootFolder.subfolders[pathParts[0]].subfolders[pathParts[1]]
            .subfolders[pathParts[2]];
      }
    }

    useImperativeHandle(ref, () => ({
      docIds: () => folder.docs.map((d) => d.id),
    }));

    return (
      <DocumentFolder
        classes={classes}
        path={path}
        folder={folder}
        isSubfolder={false}
        open
        onFilesDropped={onFilesDropped}
        removeDocument={removeDocument}
        handleOpenItem={handleOpenItem}
        handleSelectDocument={handleSelectDocument}
        selectedDocuments={selectedDocuments}
        showCheckboxes={showCheckboxes}
        isMoveOrCopyView={isMoveOrCopyView}
        refetching={refetching}
        sortBy={sortBy}
        disabled={disabled}
        handleError={handleError}
      />
    );
  }
);

export default withStyles(styles)(Documents);
