import { gql } from '@apollo/client';
import { Mutation } from '@apollo/client/react/components';
import { format } from 'date-fns';
import { useContext, useState } from 'react';
import Skeleton from 'react-loading-skeleton';
import { Link } from 'react-router-dom';

import {
  Avatar,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Collapse,
  Icon,
  IconButton,
  Tooltip,
  Typography,
} from '@material-ui/core';
import { FileCopy, Home, Star } from '@material-ui/icons';

import DeleteDialog from '../../../components/delete-dialog';
import QueryWrapper from '../../../components/query-wrapper';
import { UserContext } from '../../../contexts/user-context';
import { GET_FAV_PROJECTS, GET_PROJECTS } from '../../home/home';
import ProjectDialog from '../../home/project-dialog';

const FAVOURITE_PROJECT = gql`
  mutation FavouriteProject($id: Int!) {
    favouriteProject(id: $id)
  }
`;

const UNFAVOURITE_PROJECT = gql`
  mutation UnfavouriteProject($id: Int!) {
    unfavouriteProject(id: $id)
  }
`;

const DELETE_RESTORE_PROJECT = gql`
  mutation DeleteRestoreProject($id: Int!, $isActive: Boolean!) {
    deleteRestoreProject(id: $id, isActive: $isActive)
  }
`;
const GENERATE_API_KEY = gql`
  mutation GenerateProjectAPIKey($id: Int!) {
    generateProjectAPIKey(id: $id)
  }
`;

export const GET_PROJECT_DETAILS = gql`
  query Project($referenceNumber: String) {
    project(referenceNumber: $referenceNumber) {
      id
      name
      referenceNumber
      isFavourite
      county
      startDate
      endDate
      budget
      agreementNumber
      showCurves
      associatedProject
      showMap
      manageBlasts
      reasonForSurvey
      isActive
      apiKey
      apiInUse
      crs
      customer {
        id
        name
        address {
          id
          addressline1
          addressline2
          postalcode
          stateProvice
        }
      }
      office {
        id
        name
      }
      assistant {
        id
        name
        email
        phonenumber
      }
      contact {
        id
        name
        email
        phonenumber
      }
      responsible {
        id
        name
        email
        phonenumber
      }
      responsibleSurveying {
        id
        name
        email
        phonenumber
      }
      responsibleMeasuring {
        id
        name
        email
        phonenumber
      }
      localManager {
        id
        name
        email
        phonenumber
      }
      measuringCards {
        id
        validFrom
        validTo
        channels {
          id
          hiredFrom
          hiredTo
        }
      }
    }
  }
`;
const deleteInfo = {
  deleteMutation: DELETE_RESTORE_PROJECT,
  refetchQuery: GET_PROJECT_DETAILS,
};

const Label = ({ title, value, isLink = false, setState }) => {
  const { name, email, phoneNumber, phonenumber } = value;

  return (
    <div style={{ marginBottom: 8 }}>
      <Typography variant='subtitle2'>{title}</Typography>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <Typography variant='body2' component='span'>
          {isLink ? (
            <span
              style={{
                textDecoration: 'underline',
                cursor: 'pointer',
                color: 'blue',
              }}
              onClick={() => setState((x) => !x)}
            >
              {name || email}
            </span>
          ) : (
            <span>{name || email}</span>
          )}
        </Typography>{' '}
        <Typography variant='body2' color='textSecondary' component='span'>
          {phoneNumber || phonenumber}
        </Typography>
      </div>
    </div>
  );
};

const CollapseInfo = ({ open, label, value }) => {
  return (
    <Collapse in={open} style={{ marginBottom: '8px' }}>
      <Typography variant='subtitle2' style={{ display: 'inline' }}>
        {label}:{' '}
      </Typography>
      <Typography varaiant='body2' style={{ display: 'inline' }}>
        <span>{value}</span>
      </Typography>
    </Collapse>
  );
};

const ProjectInfoWidget = ({ referenceNumber, classes }) => {
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const [isContactDialogOpen, setIsContactDialogOpen] = useState(false);
  const [isLocalManagerInfoOpen, setIsLocalManagerInfoOpen] = useState(false);
  const user = useContext(UserContext);

  const [showProjectDialog, setShowProjectDialog] = useState(false);

  return (
    <QueryWrapper
      query={GET_PROJECT_DETAILS}
      variables={{ referenceNumber }}
      errorMessageProps={{ paper: true }}
    >
      {({ data, loading, refetch }) => {
        if (loading) {
          data = { project: { office: {}, customer: {} } };
        }

        const {
          id,
          name,
          isFavourite,
          county,
          startDate,
          endDate,
          customer,
          office,
          responsible,
          assistant,
          responsibleSurveying,
          responsibleMeasuring,
          localManager,
          contact,
          associatedProject,
          isActive,
          apiInUse,
        } = data.project;

        const apiKey = `apiKey:${data.project.apiKey}`;

        return (
          <Card className={classes.widget}>
            <CardHeader
              title={
                (name && `${referenceNumber} ${name}`) || (
                  <Skeleton width={150} />
                )
              }
              subheader={
                <>
                  <div style={{ fontSize: '12px' }}>
                    {(customer.name &&
                      `${customer?.name} (${customer.id})`) || (
                      <Skeleton width={120} />
                    )}
                    ,&nbsp;
                    {county || <Skeleton width={100} />}
                  </div>
                  <div style={{ fontSize: '12px' }}>
                    {startDate ? (
                      `${format(new Date(startDate), 'yyyy-MM-dd')} — ${format(
                        new Date(endDate),
                        'yyyy-MM-dd'
                      )}`
                    ) : (
                      <Skeleton width={210} />
                    )}
                  </div>
                </>
              }
              action={
                <Mutation
                  mutation={
                    isFavourite ? UNFAVOURITE_PROJECT : FAVOURITE_PROJECT
                  }
                  refetchQueries={(x) => [
                    {
                      query: GET_PROJECT_DETAILS,
                      variables: {
                        referenceNumber,
                      },
                    },
                    {
                      query: GET_FAV_PROJECTS,
                    },
                    {
                      query: GET_PROJECTS,
                    },
                  ]}
                >
                  {(favouriteProject) => (
                    <IconButton
                      aria-label='Add to favorites'
                      color={isFavourite ? 'secondary' : 'default'}
                      onClick={(x) => favouriteProject({ variables: { id } })}
                      style={{ marginTop: '5px', marginRight: '5px' }}
                    >
                      <Star />
                    </IconButton>
                  )}
                </Mutation>
              }
            />
            <CardContent>
              {office && office.name && (
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    marginBottom: 8,
                  }}
                >
                  <div className={classes.avatarWrapper}>
                    <Avatar className={classes.avatar}>
                      <Home />
                    </Avatar>
                  </div>
                  <Typography component='span'>
                    {office.name !== 'Falun'
                      ? `${office.name}skontoret`
                      : `Kontoret i ${office.name}`}
                  </Typography>
                </div>
              )}
              {responsible && (
                <Label title='Fakturaansvarig' value={responsible} />
              )}

              {assistant && <Label title='Projektledare' value={assistant} />}

              {responsibleSurveying && (
                <Label
                  title='Besiktningsansvarig'
                  value={responsibleSurveying}
                />
              )}

              {responsibleMeasuring && (
                <Label title='Mätansvarig' value={responsibleMeasuring} />
              )}

              {localManager && (
                <Label
                  title='Kontaktperson arbetsplats'
                  value={localManager}
                  isLink
                  setState={setIsLocalManagerInfoOpen}
                />
              )}

              {isLocalManagerInfoOpen && (
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                  }}
                >
                  <CollapseInfo
                    open={isLocalManagerInfoOpen}
                    label='Email'
                    value={localManager.email}
                  />
                  <Tooltip
                    title='Kopiera email'
                    style={{ marginLeft: '10px', marginBottom: '5px' }}
                  >
                    <IconButton
                      onClick={() => {
                        navigator.clipboard.writeText(localManager.email);
                      }}
                    >
                      <Icon fontSize='small'>file_copy</Icon>
                    </IconButton>
                  </Tooltip>
                </div>
              )}

              {name && (
                <Label
                  title='Fakturareferens'
                  value={
                    contact || {
                      name: 'Referens saknas. Ändra projektet för att lägga till.',
                    }
                  }
                  isLink={!!contact}
                  setState={setIsContactDialogOpen}
                />
              )}

              {isContactDialogOpen && (
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                  }}
                >
                  <CollapseInfo
                    open={isContactDialogOpen}
                    label='Email'
                    value={contact.email}
                  />
                  <Tooltip
                    title='Kopiera email'
                    style={{ marginLeft: '10px', marginBottom: '5px' }}
                  >
                    <IconButton
                      onClick={() => {
                        navigator.clipboard.writeText(contact.email);
                      }}
                    >
                      <Icon fontSize='small'>file_copy</Icon>
                    </IconButton>
                  </Tooltip>
                </div>
              )}

              {associatedProject && (
                <div style={{ marginBottom: 8 }}>
                  <Typography variant='subtitle2'>Relaterat projekt</Typography>
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                    }}
                  >
                    <Typography variant='body2' component='span'>
                      <Link to={`/project/${associatedProject}`}>
                        {associatedProject}
                      </Link>
                    </Typography>
                  </div>
                </div>
              )}

              {['Admin', 'SuperAdmin'].includes(user.role) && apiInUse && (
                <div style={{ marginBottom: 8 }}>
                  <Typography variant='subtitle2'>API-nyckel</Typography>
                  <div
                    style={{
                      display: 'flex',
                      gap: 4,
                      justifyContent: 'start',
                    }}
                  >
                    <Typography
                      variant='body2'
                      component='span'
                      style={{ paddingTop: '5px' }}
                    >
                      {apiKey}
                    </Typography>
                    <Tooltip title='Kopiera API-nyckel'>
                      <IconButton
                        onClick={() => {
                          navigator.clipboard?.writeText(apiKey);
                        }}
                      >
                        <FileCopy fontSize='small' />
                      </IconButton>
                    </Tooltip>
                    <Mutation
                      mutation={GENERATE_API_KEY}
                      refetchQueries={(x) => [
                        {
                          query: GET_PROJECT_DETAILS,
                          variables: {
                            referenceNumber,
                          },
                        },
                      ]}
                    >
                      {(generateProjectAPIKey) => (
                        <Button
                          size='small'
                          style={{ marginLeft: 'auto' }}
                          onClick={() =>
                            generateProjectAPIKey({ variables: { id } })
                          }
                        >
                          regenerera
                        </Button>
                      )}
                    </Mutation>
                  </div>
                </div>
              )}
            </CardContent>

            <CardActions
              style={{ justifyContent: 'space-between', marginTop: 20 }}
            >
              {isActive ? (
                <>
                  <Button
                    size='small'
                    onClick={() => setShowProjectDialog(true)}
                  >
                    Ändra projekt
                  </Button>
                  <Button
                    size='small'
                    onClick={() => setIsDeleteDialogOpen(true)}
                  >
                    <span style={{ color: 'red' }}>Ta bort projekt</span>
                  </Button>
                </>
              ) : id && !isActive ? (
                <span style={{ color: 'red', fontWeight: 'bold' }}>
                  Projeketet är borttaget
                </span>
              ) : (
                ''
              )}
            </CardActions>
            {showProjectDialog && (
              <ProjectDialog
                isProjectModalOpen={showProjectDialog}
                handleProjectModalClose={() => setShowProjectDialog(false)}
                project={data.project}
              />
            )}
            <DeleteDialog
              isModalOpen={isDeleteDialogOpen}
              handleModalClose={() => setIsDeleteDialogOpen(false)}
              referenceNumber={referenceNumber}
              data={data.project}
              deleteInfo={deleteInfo}
            />
          </Card>
        );
      }}
    </QueryWrapper>
  );
};

export default ProjectInfoWidget;
