import { MutableRefObject, createContext } from 'react';

interface AuthContextProps {
  isAuthenticated: boolean;
  isComplete: boolean;
  accessToken?: string;
  accessTokenRef: MutableRefObject<string | undefined>;
  login: (queryParameters?: { [key: string]: string }) => void;
  logout: () => void;
}

export const AuthContext = createContext<AuthContextProps | undefined>(
  undefined
);
AuthContext.displayName = 'AuthContext';
