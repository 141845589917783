import React, { useEffect, useMemo } from 'react';
import useSWR from 'swr';

import { useRequiredContext } from './common/hooks-util';
import { AuthContext } from './contexts/auth-context';
import { UserContext, UserContextProps } from './contexts/user-context';
import { ApiError, UserService } from './generated/tellus';

export const UserProvider: React.FC = ({ children }) => {
  const { isAuthenticated, isComplete, logout } =
    useRequiredContext(AuthContext);

  const { data, error, isLoading, mutate } = useSWR(
    isComplete && isAuthenticated && { _key: 'Me' },
    UserService.getUser
  );

  // Logout if there is an authentication error.
  useEffect(() => {
    if (error instanceof ApiError && error.status === 403) {
      logout();
    }
  }, [error, logout]);

  const user = useMemo((): UserContextProps | undefined => {
    if (isLoading || data == null) {
      return;
    }

    return {
      id: data.id.toString(),
      email: data.email,
      phonenumber: data.phonenumber,
      name: data.name,
      role: data.role,
      allowedProjectIds: data.allowedProjectIds,
      allowedMeasuringPoints: data.allowedMeasuringPoints,
      updateUser: () => mutate(),
    };
  }, [data, isLoading, mutate]);

  return <UserContext.Provider value={user}>{children}</UserContext.Provider>;
};
