import { useCallback, useContext, useMemo } from 'react';
import { Link } from 'react-router-dom';

import { Drawer, Hidden, withStyles } from '@material-ui/core';

import ProjectContext from '../../contexts/project-context';
import ProjectMenuItems from '../../pages/project/project-menu-items';
import logo from './metron_logo.png';
import styles from './styles';

function SideNavigation({ classes, drawerOpen, setDrawerOpen }) {
  const projectContext = useContext(ProjectContext);

  const handleCloseDrawer = useCallback(
    () => setDrawerOpen(false),
    [setDrawerOpen]
  );

  const drawer = useMemo(() => {
    return (
      <>
        <Link to='/'>
          <img src={logo} alt='Logo' className={classes.logo} />
        </Link>
        <ProjectMenuItems
          projectContext={projectContext}
          closeDrawer={handleCloseDrawer}
        />
      </>
    );
  }, [classes.logo, projectContext, handleCloseDrawer]);

  const drawerClasses = useMemo(
    () => ({
      paper: classes.drawerPaper,
      docked: classes.drawerPaper,
    }),
    [classes.drawerPaper]
  );

  return (
    <>
      <Hidden mdUp>
        <Drawer
          variant='temporary'
          open={drawerOpen}
          onClose={handleCloseDrawer}
          classes={drawerClasses}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
        >
          {drawer}
        </Drawer>
      </Hidden>
      <Hidden smDown>
        <Drawer classes={drawerClasses} variant='permanent' open>
          {drawer}
        </Drawer>
      </Hidden>
    </>
  );
}

export default withStyles(styles)(SideNavigation);
