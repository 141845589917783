import { gql, useApolloClient } from '@apollo/client';
import { useState } from 'react';
import Dropzone from 'react-dropzone';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';

import {
  Badge,
  Button,
  CircularProgress,
  GridList,
  GridListTile,
  GridListTileBar,
  IconButton,
  TextField,
  Typography,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { Close, CloudDownload, CloudUpload, Create } from '@material-ui/icons';

import { useRequiredContext } from '../../common/hooks-util';
import { documentDownloadUrl } from '../../common/tellus-util';
import DialogWrapper from '../../components/dialog-wrapper';
import ErrorMessageDialog from '../../components/error-message-dialog';
import QueryWrapper from '../../components/query-wrapper';
import { AuthContext } from '../../contexts/auth-context';
import { UploadConsumer } from '../../contexts/upload-context';

const GET_IMAGES_AND_DOCUMENTS = gql`
  query GalleryImages($projectReference: String!) {
    project(referenceNumber: $projectReference) {
      id
      name
      referenceNumber
      documents {
        edges {
          node {
            id
            filename
            mime
            path
            tags
            modifiedAt
            modifiedBy
            isActive
            projectReference
            uploadId
            children {
              id
              filename
              uploadId
            }
          }
        }
      }
    }
  }
`;

export const UPDATE_IMAGE_GALLERY = gql`
  mutation UpdateImageGallery(
    $id: Int!
    $tags: String
    $path: String!
    $projectReference: String!
  ) {
    updateImageGallery(
      id: $id
      tags: $tags
      path: $path
      projectReference: $projectReference
    )
  }
`;

const styles = (theme) => ({
  root: {
    padding: 10,
  },
  listTile: {
    width: '100%',
    height: '100%',
    cursor: 'pointer',
    '&:hover': {
      opacity: '0.8',
    },
  },
  grow: {
    flex: '1 auto',
  },
  icon: {
    color: 'rgba(255, 255, 255, 0.54)',
  },
  subHeader: {
    display: 'flex',
    width: '95%',
    alignItems: 'center',
    paddingTop: 7,
  },
  formWrapper: {
    display: 'flex',
    alignItems: 'center',
  },
  input: {
    display: 'none',
    width: '100%',
    height: '100%',
  },
  imageUploadWrapper: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
    height: 100,
  },
  fileDropWrapper: {
    width: '70%',
    height: '100%',
    cursor: 'pointer',
    border: '1px dashed darkgrey',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
});

// Force it to never split the zip by using int32 max value.
const MAX_IMAGES_PER_ZIP = Math.pow(2, 31) - 1;

function GalleryDialog({
  open,
  onClose,
  classes,
  projectReference,
  title,
  galleryDocumentId,
  doc,
}) {
  const client = useApolloClient();
  const { accessTokenRef } = useRequiredContext(AuthContext);

  const [changeTagName, setChangeTagName] = useState(false);
  const [inputTagsTitle, setInputTagsTitel] = useState(title);
  const [lightboxOpen, setLightboxOpen] = useState(false);
  const [zipInProgress, setZipInProgress] = useState(false);
  const [isUploadImages, setIsUploadImages] = useState(false);
  const [previewIndex, setPreviewIndex] = useState(0);
  const [previewUrls, setPreviewUrls] = useState([]);
  const [hasUploadError, setHasUploadError] = useState(false);

  const handleChange = (e) => {
    setInputTagsTitel(e.target.value);
  };

  const handleCancelClick = (e) => {
    setChangeTagName(false);
    setInputTagsTitel(title);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await client.mutate({
        mutation: UPDATE_IMAGE_GALLERY,
        variables: {
          id: doc.id,
          tags: inputTagsTitle,
          path: doc.path,
          projectReference: doc.projectReference,
        },
        refetchQueries: () => ['Documents'],
      });
    } catch (error) {
      console.error(error);
    }
    setChangeTagName(false);
  };

  const handleImageClick = (imgDocs, index) => {
    setPreviewUrls(createPreviewUrls(imgDocs));
    setPreviewIndex(index);
    setLightboxOpen(true);
  };

  const handleSingleImageDownload = (id) => {
    const a = document.createElement('a');
    a.href = documentDownloadUrl(id, accessTokenRef.current);
    a.click();
  };

  const handleAlbumDownload = async (id, totalCount, albumName) => {
    try {
      setZipInProgress(true);
      let downloadUriBase = `${process.env.REACT_APP_API_URI}document/zip/${id}?auth=${accessTokenRef.current}&totalCount=${totalCount}&imagesPerZip=${MAX_IMAGES_PER_ZIP}&albumName=${albumName}`;

      for (
        var zipIndex = 0;
        zipIndex * MAX_IMAGES_PER_ZIP < totalCount;
        zipIndex++
      ) {
        let downloadUri = `${downloadUriBase}&currentZipIndex=${zipIndex}`;
        downloadZip(id, downloadUri, albumName, zipIndex);
      }
    } catch (err) {
      console.error(err);
    } finally {
      setZipInProgress(false);
    }
  };

  const createPreviewUrls = (imgDocs) => {
    return imgDocs.map((d) => {
      return {
        fileName: d.filename,
        src: `${process.env.REACT_APP_THUMBNAIL_SERVICE_URI}?uploadId=${d.uploadId}&size=1100&filename=${d.filename}&bucket=${process.env.REACT_APP_THUMBNAIL_S3_BUCKET}&code=${process.env.REACT_APP_THUMBNAIL_SERVICE_TOKEN}`,
      };
    });
  };

  const downloadZip = (id, downloadUri, albumName, zipIndex) => {
    const ifr = document.createElement('iframe');
    ifr.id = 'ifr-' + id + '-' + zipIndex;
    ifr.style.display = 'none';
    ifr.style.height = 0;
    ifr.src = downloadUri;
    document.body.appendChild(ifr);
    setTimeout(() => {
      ifr.parentNode.removeChild(ifr);
    }, (zipIndex + 1) * 3000);
  };

  return (
    <DialogWrapper
      open={open}
      onClose={onClose}
      aria-labelledby='gallery-dialog'
    >
      <div className={classes.root}>
        <QueryWrapper
          query={GET_IMAGES_AND_DOCUMENTS}
          variables={{ projectReference }}
          notifyOnNetworkStatusChange
        >
          {({ loading, data, refetch }) => {
            if (loading) {
              return (
                <CircularProgress style={{ width: '30px', height: '30px' }} />
              );
            }

            const imgDocs = data.project.documents.edges.filter(
              (d) => d.node.id === galleryDocumentId
            );
            if (imgDocs.length === 0) {
              return (
                <div style={{ textAlign: 'center' }}>
                  <Typography variant='body2'>
                    Oj något gick fel... Tryck på Uppdatera för att försöka få
                    fram bilderna.
                  </Typography>
                  <Button
                    variant='contained'
                    style={{ marginTop: 15 }}
                    onClick={() => refetch()}
                  >
                    Uppdatera
                  </Button>
                </div>
              );
            }

            const imgSortedDocs = [...imgDocs[0].node.children].sort((a, b) =>
              a.filename.localeCompare(b.filename)
            );

            const uploadIdForImages = imgSortedDocs[0].uploadId;

            return (
              <GridList cellHeight={200} cols={6} className={classes.gridList}>
                <UploadConsumer>
                  {({ uploadFiles }) => {
                    const uploading = (files) => {
                      uploadFiles(
                        setHasUploadError,
                        refetch,
                        data.project,
                        doc.path,
                        files,
                        false,
                        uploadIdForImages,
                        doc.id
                      );
                      setIsUploadImages(false);
                    };
                    return (
                      <>
                        {isUploadImages && (
                          <div className={classes.imageUploadWrapper}>
                            <Dropzone
                              onDrop={(acceptedFiles) =>
                                uploading(acceptedFiles)
                              }
                              accept='image/jpg, image/jpeg'
                              className={classes.fileDropWrapper}
                            >
                              {({ isDragAccept }) => (
                                <div>
                                  <input
                                    id='image-upload'
                                    onChange={(e) => uploading(e)}
                                    className={classes.input}
                                  />
                                  <Typography variant='body2'>
                                    {isDragAccept
                                      ? 'Uppladning börjar snart'
                                      : 'Dra filerna hit eller tryck här för att välja filer'}
                                  </Typography>
                                </div>
                              )}
                            </Dropzone>
                          </div>
                        )}
                      </>
                    );
                  }}
                </UploadConsumer>
                <GridListTile
                  key='Subheader'
                  cols={6}
                  style={{ height: 'auto' }}
                >
                  <div className={classes.subHeader}>
                    {!changeTagName ? (
                      <Typography variant='subheading'>
                        {inputTagsTitle !== null
                          ? `Albumets titel: ${inputTagsTitle}`
                          : 'Ingen titel på album'}
                      </Typography>
                    ) : (
                      <>
                        <form
                          onSubmit={(e) => handleSubmit(e)}
                          className={classes.formWrapper}
                        >
                          <TextField
                            onChange={(e) => handleChange(e)}
                            value={(inputTagsTitle && inputTagsTitle) || ''}
                            name='tags'
                            placeholder='Albumets titel'
                          />
                          <Button
                            size='small'
                            variant='contained'
                            color='primary'
                            style={{ marginLeft: 15 }}
                            type='submit'
                          >
                            Spara
                          </Button>
                          <Button
                            size='small'
                            style={{ marginLeft: 5 }}
                            onClick={() => handleCancelClick()}
                          >
                            Avbryt
                          </Button>
                        </form>
                      </>
                    )}
                    <div className={classes.grow} />
                    <IconButton
                      title={'Ladda upp fler bilder'}
                      component='span'
                      onClick={() => setIsUploadImages(!isUploadImages)}
                    >
                      {isUploadImages ? <Close /> : <CloudUpload />}
                    </IconButton>

                    <IconButton
                      title={'Ändra titel på albumet'}
                      onClick={() => setChangeTagName(true)}
                    >
                      <Create />
                    </IconButton>
                    {zipInProgress ? (
                      <CircularProgress
                        style={{ width: '18px', height: '18px' }}
                      />
                    ) : (
                      <IconButton
                        title={'Ladda hem alla bilder'}
                        onClick={(e) => {
                          e.preventDefault();
                          setZipInProgress(true);
                          handleAlbumDownload(
                            uploadIdForImages,
                            imgSortedDocs ? imgSortedDocs.length : 0,
                            inputTagsTitle
                              ? `Albumets titel: ${inputTagsTitle}`
                              : 'Ingen titel på album'
                          );
                        }}
                      >
                        <Badge
                          badgeContent={imgSortedDocs.length}
                          color='primary'
                          title={'Antal bilder i albumet'}
                          max={1000}
                        >
                          <CloudDownload />
                        </Badge>
                      </IconButton>
                    )}
                  </div>
                </GridListTile>
                {imgSortedDocs.map((imgDoc, idx) => (
                  <GridListTile key={imgDoc.id} cols={2} rows={1}>
                    <img
                      src={`${process.env.REACT_APP_THUMBNAIL_SERVICE_URI}?uploadId=${imgDoc.uploadId}&size=170&filename=${imgDoc.filename}&bucket=${process.env.REACT_APP_THUMBNAIL_S3_BUCKET}&code=${process.env.REACT_APP_THUMBNAIL_SERVICE_TOKEN}`}
                      alt={imgDoc.filename}
                      onClick={() => {
                        handleImageClick(imgSortedDocs, idx);
                      }}
                      className={classes.listTile}
                    />
                    <GridListTileBar
                      title={<span>{imgDoc.filename}</span>}
                      titlePosition='top'
                      actionPosition='right'
                      actionIcon={
                        <>
                          <IconButton
                            className={classes.icon}
                            title={'Ladda hem bild'}
                            onClick={() => handleSingleImageDownload(imgDoc.id)}
                          >
                            <CloudDownload />
                          </IconButton>
                        </>
                      }
                    />
                  </GridListTile>
                ))}
              </GridList>
            );
          }}
        </QueryWrapper>
        {lightboxOpen && (
          <Lightbox
            mainSrc={previewUrls[previewIndex].src}
            nextSrc={previewUrls[(previewIndex + 1) % previewUrls.length].src}
            prevSrc={
              previewUrls[
                (previewIndex + previewUrls.length - 1) % previewUrls.length
              ].src
            }
            imageTitle={previewUrls[previewIndex].fileName}
            onMoveNextRequest={() =>
              setPreviewIndex((previewIndex + 1) % previewUrls.length)
            }
            onMovePrevRequest={() =>
              setPreviewIndex(
                (previewIndex + previewUrls.length - 1) % previewUrls.length
              )
            }
            onCloseRequest={() => setLightboxOpen(false)}
            reactModalStyle={{
              overlay: { zIndex: 1500 },
            }}
          />
        )}
      </div>
      <ErrorMessageDialog
        isModalOpen={hasUploadError}
        handleModalClose={() => setHasUploadError(false)}
        message={
          'Uppladdningen misslyckades. Vänligen kontrollera den uppladdade filen och ladda upp den igen om det behövs.'
        }
      />
    </DialogWrapper>
  );
}

export default withStyles(styles)(GalleryDialog);
