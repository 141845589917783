import { gql, useApolloClient, useMutation, useQuery } from '@apollo/client';
import { addYears, format } from 'date-fns';
import { Formik } from 'formik';
import { useMemo, useRef, useState } from 'react';
import * as Yup from 'yup';

import {
  Button,
  Checkbox,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { Error as ErrorIcon } from '@material-ui/icons';
import { KeyboardDatePicker } from '@material-ui/pickers';

import { crsValues } from '../../common/crs';
import {
  getErrorType,
  handleError as handleSeverError,
} from '../../common/graphql';
import AutoComplete from '../../components/auto-complete';
import DialogWrapper from '../../components/dialog-wrapper';
import ErrorMessageDialog from '../../components/error-message-dialog';
import { GET_PROJECT_DETAILS } from '../project/dashboard/project-info-widget';

const ProjectSchema = Yup.object().shape({
  name: Yup.string().min(2, 'För kort').required('Namn är obligatoriskt'),
  customer: Yup.string().required('Kund är obligatoriskt'),
  contact: Yup.string().required('Fakturareferens är obligatorisk'),
  startDate: Yup.date()
    .typeError('Ogiltigt datumformat')
    .max(Yup.ref('endDate'), 'Startdatum måste vara innan slutdatum')
    .required('Startdatum är obligatorisk'),
  endDate: Yup.date()
    .typeError('Ogiltigt datumformat')
    .min(Yup.ref('startDate'), 'Slutdatum måste vara efter startdatum')
    .required('Slutdatum är obligatorisk'),
  responsible: Yup.string().required('Fakturaansvarig är obligatorisk'),
  assistant: Yup.string().required('Projektledare är obligatorisk'),
  county: Yup.string().required('Ort är obligatorisk'),
  office: Yup.string().required('Kontor är obligatorisk'),
  budget: Yup.number()
    .typeError('Budget måste vara ett nummer.')
    .min(0, 'Budget kan inte vara negativ.')
    .integer('Budget måste vara ett heltal.'),
});

export const GET_DATA = gql`
  {
    customers {
      id
      name
    }
    offices {
      id
      name
    }
    users {
      id
      email
      name
      role
    }
  }
`;

const UPDATE_PROJECT = gql`
  mutation ($projectInput: ProjectInput!) {
    updateProject(project: $projectInput)
  }
`;

const CREATE_PROJECT = gql`
  mutation ($projectInput: ProjectInput!) {
    createProject(project: $projectInput)
  }
`;

const EXPORT_PROJECT = gql`
  mutation ExportProject($projectId: Int!, $isNew: Boolean!) {
    exportProject(projectId: $projectId, isNew: $isNew)
  }
`;

const ResponsibleSelect = ({
  id,
  label,
  rootAnchorEl,
  value,
  initialSelectedItem,
  onChange,
  onBlur,
  helperText,
  error,
  anchorEl,
  required,
  name,
  items,
  disabled,
}) => (
  <div ref={rootAnchorEl}>
    <AutoComplete
      id={id}
      label={label}
      value={value}
      required={required}
      initialSelectedItem={initialSelectedItem}
      placeholder='Namn'
      items={items}
      name={name}
      onChange={onChange}
      onBlur={onBlur}
      helperText={helperText}
      error={error}
      anchorEl={anchorEl}
      disabled={disabled}
    />
  </div>
);

const UserSelect = ({
  id,
  label,
  rootAnchorEl,
  value,
  initialSelectedItem,
  onChange,
  onBlur,
  helperText,
  error,
  anchorEl,
  required,
  name,
  users,
  disabled,
}) => (
  <div ref={rootAnchorEl}>
    <AutoComplete
      id={id}
      label={label}
      value={value}
      required={required}
      initialSelectedItem={initialSelectedItem}
      placeholder='E-postadress'
      items={users}
      name={name}
      onChange={onChange}
      onBlur={onBlur}
      helperText={helperText}
      error={error}
      anchorEl={anchorEl}
      disabled={disabled}
    />
  </div>
);

const styles = {
  menu: {
    width: 200,
  },
  content: {
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'column',
  },
  flexRow: {
    display: 'flex',
    alignItems: 'center',
  },
};

const exportProject = async (client, projectId, isNew) => {
  await client.mutate({
    mutation: EXPORT_PROJECT,
    variables: {
      projectId: projectId,
      isNew: isNew,
    },
  });
};

const ProjectDialog = ({
  isProjectModalOpen,
  handleProjectModalClose,
  classes,
  project,
  refetchLists,
}) => {
  const customerAnchorEl = useRef(null);
  const contactAnchorEl = useRef(null);
  const localManagerAnchorEl = useRef(null);
  const responsibleAnchorEl = useRef(null);
  const assistantAnchorEl = useRef(null);
  const responsibleSurveyingAnchorEl = useRef(null);
  const responsibleMeasuringAnchorEl = useRef(null);
  const [qbisErrorMessage, setQbisErrorMessage] = useState('');

  const client = useApolloClient();
  const { data, loading: dataLoading } = useQuery(GET_DATA, {
    fetchPolicy: 'cache-and-network',
  });

  const [createProject] = useMutation(
    project?.id ? UPDATE_PROJECT : CREATE_PROJECT,
    {
      refetchQueries: () => {
        return project.id
          ? [
              {
                query: GET_PROJECT_DETAILS,
                variables: {
                  referenceNumber: project.referenceNumber,
                },
              },
            ]
          : refetchLists.map((q) => ({
              query: q,
            }));
      },
    }
  );

  const adminUsers = useMemo(
    () =>
      data?.users?.filter((u) => ['Admin', 'SuperAdmin'].includes(u.role)) ??
      [],
    [data?.users]
  );

  const users = useMemo(
    () =>
      data?.users?.map((u) => ({
        id: u.id,
        name: u.email,
        optionalSearchParameter: u.name,
      })) ?? [],
    [data?.users]
  );

  const customers = useMemo(
    () =>
      data?.customers?.map((c) => ({
        id: c.id,
        name: `${c.id} - ${c.name}`,
      })) ?? [],
    [data?.customers]
  );

  if (!project) {
    project = {
      customer: {},
      office: {},
      assistant: {},
      contact: {},
      responsibleSurveying: {},
      responsibleMeasuring: {},
      localManager: {},
      responsible: {},
    };
  }

  return (
    <DialogWrapper
      open={isProjectModalOpen}
      onClose={handleProjectModalClose}
      ariaLabelledby='create-project-dialog'
    >
      <Formik
        initialValues={{
          id: project.id,
          name: project.name || '',
          county: project.county || '',
          customer: project.customer.id || '',
          contact: project.contact?.id || '',
          startDate: project.startDate || format(new Date(), 'yyyy-MM-dd'),
          endDate:
            project.endDate || format(addYears(new Date(), 3), 'yyyy-MM-dd'),
          responsible: project.responsible?.id || '',
          responsibleSurveying: project.responsibleSurveying?.id || '',
          responsibleMeasuring: project.responsibleMeasuring?.id || '',
          localManager: project.localManager?.id || '',
          assistant: project.assistant?.id || '',
          office: project.office.id || '',
          budget: project.budget || '',
          agreementNumber: project.agreementNumber || '',
          description: project.description || '',
          showCurves: true,
          showMap: true,
          manageBlasts: true,
          crs: project.crs ?? 3007,
          apiInUse: project.apiInUse || false,
          reasonForSurvey:
            project.reasonForSurvey ||
            `Vibrationsalstrande markarbeten inom projekt ${
              project.name || ''
            }${project.name ? ',' : ''} ${project.county || ''}`,
        }}
        validationSchema={ProjectSchema}
        validateOnChange={false}
        onSubmit={async (values, { setSubmitting, setFieldError }) => {
          const variables = { ...values };
          // MUI selects cannot use null as value so we use
          // empty string and convert to null here

          // Convert budget to number. Fall back to null if it failed to parse.
          variables.budget = parseInt(variables.budget) || null;

          if (variables.assistant === '') variables.assistant = null;
          if (variables.responsibleSurveying === '') {
            variables.responsibleSurveying = null;
          }
          if (variables.responsibleMeasuring === '') {
            variables.responsibleMeasuring = null;
          }
          if (variables.localManager === '') variables.localManager = null;
          if (variables.agreementNumber === '') {
            variables.agreementNumber = null;
          }
          if (
            variables.reasonForSurvey ===
              'Vibrationsalstrande markarbeten inom projekt  ' ||
            variables.reasonForSurvey ===
              'Vibrationsalstrande markarbeten inom projekt '
          ) {
            variables.reasonForSurvey = `Vibrationsalstrande markarbeten inom projekt ${variables.name}, ${variables.county} `;
          }

          variables.startDate = format(
            new Date(variables.startDate),
            'yyyy-MM-dd'
          );
          variables.endDate = format(new Date(variables.endDate), 'yyyy-MM-dd');

          try {
            const result = await createProject({
              variables: { projectInput: variables },
            });
            if (!!project.id) {
              await exportProject(client, project.id, false);
            } else {
              if (result && result.data && result.data.createProject) {
                await exportProject(client, result.data.createProject, true);
              }
            }
            handleProjectModalClose();
          } catch (error) {
            const errorMessage = handleSeverError(error);
            if (getErrorType(error) === 'QBISIntegration') {
              setQbisErrorMessage(errorMessage);
            } else {
              setFieldError('general', errorMessage);
            }
          } finally {
            setSubmitting(false);
          }
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
        }) => {
          const disableForm = dataLoading || isSubmitting;
          return (
            <form onSubmit={handleSubmit} className='modal-form'>
              <DialogTitle>
                {project.id ? 'Ändra projekt' : 'Nytt projekt'}
              </DialogTitle>
              <div className={classes.content}>
                <DialogContent>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        fullWidth
                        autoFocus
                        margin='dense'
                        name='name'
                        label='Projektnamn'
                        required
                        value={values.name}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        helperText={touched.name && errors.name}
                        error={touched.name && !!errors.name}
                        disabled={disableForm}
                      />
                    </Grid>

                    <Grid item xs={12} sm={6}>
                      <TextField
                        fullWidth
                        margin='dense'
                        name='county'
                        label='Ort'
                        required
                        value={values.county}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        helperText={touched.county && errors.county}
                        error={touched.county && !!errors.county}
                        disabled={disableForm}
                      />
                    </Grid>

                    <Grid item xs={12} sm={6}>
                      <KeyboardDatePicker
                        fullWidth
                        margin='dense'
                        name='startDate'
                        label='Startdatum'
                        format='yyyy-MM-dd'
                        required
                        value={values.startDate || new Date()}
                        onChange={(value) =>
                          handleChange({
                            target: { name: 'startDate', value },
                          })
                        }
                        onBlur={handleBlur}
                        helperText={touched.startDate && errors.startDate}
                        error={touched.startDate && !!errors.startDate}
                        disabled={disableForm}
                      />
                    </Grid>

                    <Grid item xs={12} sm={6}>
                      <KeyboardDatePicker
                        fullWidth
                        margin='dense'
                        name='endDate'
                        label='Slutdatum'
                        format='yyyy-MM-dd'
                        required
                        value={values.endDate}
                        onChange={(value) =>
                          handleChange({
                            target: { name: 'endDate', value },
                          })
                        }
                        onBlur={handleBlur}
                        helperText={touched.endDate && errors.endDate}
                        error={touched.endDate && !!errors.endDate}
                        disabled={disableForm}
                      />
                    </Grid>

                    <Grid item xs={12} sm={6}>
                      <div ref={customerAnchorEl}>
                        <AutoComplete
                          id='customer-autocomplete'
                          label='Kund'
                          required
                          placeholder='Skriv för att söka'
                          items={customers}
                          name='customer'
                          initialSelectedItem={
                            project.customer && project.customer.name
                              ? `${project.customer.id} - ${project.customer.name}`
                              : ''
                          }
                          onChange={(e) => {
                            handleChange(e);
                          }}
                          onBlur={handleBlur}
                          helperText={touched.customer && errors.customer}
                          error={touched.customer && !!errors.customer}
                          anchorEl={customerAnchorEl.current}
                          disabled={disableForm}
                        />
                      </div>
                    </Grid>

                    <Grid item xs={12} sm={6}>
                      <UserSelect
                        id='contact-autocomplete'
                        label='Fakturareferens (Kundkontakt)'
                        rootAnchorEl={contactAnchorEl}
                        value={values.contact}
                        initialSelectedItem={project.contact?.email ?? ''}
                        users={users}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        helperText={
                          'Skapa först användaren om e-mailen inte finns.'
                        }
                        error={touched.contact && !!errors.contact}
                        anchorEl={contactAnchorEl.current}
                        required
                        name='contact'
                        disabled={disableForm}
                      />
                    </Grid>

                    <Grid item xs={12} sm={6}>
                      <UserSelect
                        id='local-manager-autocomplete'
                        label='Kontaktperson arbetsplats'
                        rootAnchorEl={localManagerAnchorEl}
                        value={values.localManager}
                        required
                        initialSelectedItem={project.localManager?.email ?? ''}
                        users={users}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        helperText={touched.localManager && errors.localManager}
                        error={touched.localManager && !!errors.localManager}
                        anchorEl={localManagerAnchorEl.current}
                        name='localManager'
                        disabled={disableForm}
                      />
                    </Grid>

                    <Grid item xs={12} sm={6}>
                      <FormControl
                        className={classes.formControl}
                        fullWidth
                        margin='dense'
                        required
                        error={!!touched.office && !!errors.office}
                      >
                        <InputLabel htmlFor='office'>Kontor</InputLabel>
                        <Select
                          fullWidth
                          inputProps={{
                            name: 'office',
                            id: 'office',
                          }}
                          required
                          value={values.office}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          disabled={disableForm}
                        >
                          {data?.offices?.map((c) => (
                            <MenuItem value={c.id} key={c.id}>
                              {c.name}
                            </MenuItem>
                          ))}
                        </Select>
                        <FormHelperText>
                          {touched.office && errors.office}
                        </FormHelperText>
                      </FormControl>
                    </Grid>

                    <Grid item xs={12} sm={6}>
                      <ResponsibleSelect
                        id='responsible-autocomplete'
                        label='Fakturaansvarig'
                        required
                        rootAnchorEl={responsibleAnchorEl}
                        value={values.responsible}
                        initialSelectedItem={project.responsible?.name}
                        onChange={(e) => handleChange(e)}
                        onBlur={handleBlur}
                        helperText={touched.responsible && errors.responsible}
                        error={touched.responsible && !!errors.responsible}
                        anchorEl={responsibleAnchorEl.current}
                        name='responsible'
                        items={adminUsers}
                        disabled={disableForm}
                      />
                    </Grid>

                    <Grid item xs={12} sm={6}>
                      <div ref={assistantAnchorEl}>
                        <ResponsibleSelect
                          id='assistant-autocomplete'
                          label='Projektledare'
                          required
                          rootAnchorEl={assistantAnchorEl}
                          value={values.assistant}
                          initialSelectedItem={project.assistant?.name}
                          onChange={(e) => handleChange(e)}
                          onBlur={handleBlur}
                          helperText={touched.assistant && errors.assistant}
                          error={touched.assistant && !!errors.assistant}
                          anchorEl={assistantAnchorEl.current}
                          name='assistant'
                          items={adminUsers}
                          disabled={disableForm}
                        />
                      </div>
                    </Grid>

                    <Grid item xs={12} sm={6}>
                      <div ref={responsibleSurveyingAnchorEl}>
                        <ResponsibleSelect
                          id='responsible-surveying-autocomplete'
                          label='Besiktningsansvarig'
                          rootAnchorEl={responsibleSurveyingAnchorEl}
                          value={values.responsibleSurveying}
                          initialSelectedItem={
                            project.responsibleSurveying?.name
                          }
                          onChange={(e) => handleChange(e)}
                          onBlur={handleBlur}
                          helperText={
                            touched.responsibleSurveying &&
                            errors.responsibleSurveying
                          }
                          error={
                            touched.responsibleSurveying &&
                            !!errors.responsibleSurveying
                          }
                          anchorEl={responsibleSurveyingAnchorEl.current}
                          name='responsibleSurveying'
                          items={adminUsers}
                          disabled={disableForm}
                        />
                      </div>
                    </Grid>

                    <Grid item xs={12} sm={6}>
                      <div ref={responsibleMeasuringAnchorEl}>
                        <ResponsibleSelect
                          id='responsible-measuring-autocomplete'
                          label='Mätansvarig'
                          rootAnchorEl={responsibleMeasuringAnchorEl}
                          value={values.responsibleMeasuring}
                          initialSelectedItem={
                            project.responsibleMeasuring?.name
                          }
                          onChange={(e) => handleChange(e)}
                          onBlur={handleBlur}
                          helperText={
                            touched.responsibleMeasuring &&
                            errors.responsibleMeasuring
                          }
                          error={
                            touched.responsibleMeasuring &&
                            !!errors.responsibleMeasuring
                          }
                          anchorEl={responsibleMeasuringAnchorEl.current}
                          name='responsibleMeasuring'
                          items={adminUsers}
                          disabled={disableForm}
                        />
                      </div>
                    </Grid>

                    <Grid item xs={12} sm={6}>
                      <TextField
                        fullWidth
                        margin='dense'
                        name='agreementNumber'
                        label='Referensnummer/avtalsnummer'
                        value={values.agreementNumber}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        disabled={disableForm}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        fullWidth
                        margin='dense'
                        name='budget'
                        label='Budget'
                        value={values.budget}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={touched.budget && errors.budget}
                        helperText={touched.budget && errors.budget}
                        disabled={disableForm}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        fullWidth
                        margin='dense'
                        name='reasonForSurvey'
                        label='Anledning till besiktning'
                        value={values.reasonForSurvey}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        disabled={disableForm}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Grid container>
                        <Grid item xs={6}>
                          <FormControl
                            className={classes.formControl}
                            fullWidth
                            margin='dense'
                            required
                            error={!!touched.crs && !!errors.crs}
                          >
                            <InputLabel htmlFor='crs'>
                              Koordinatsystem
                            </InputLabel>
                            <Select
                              fullWidth
                              inputProps={{
                                name: 'crs',
                                id: 'crs',
                              }}
                              value={values.crs}
                              onChange={(e) => {
                                handleChange(e);
                              }}
                              onBlur={handleBlur}
                            >
                              {Object.keys(crsValues).map((id) => (
                                <MenuItem value={Number(id)} key={id}>
                                  {crsValues[id].name}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </Grid>
                        <Grid item xs={6}>
                          <FormControlLabel
                            control={
                              <Checkbox
                                name={'apiInUse'}
                                checked={values.apiInUse}
                                value={values.apiInUse.toString()}
                                onChange={handleChange}
                                color='primary'
                                disabled={disableForm}
                              />
                            }
                            label='Aktivera Web API'
                            style={{ marginLeft: '0px' }}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                    {!!errors.general && (
                      <Grid item xs={12}>
                        <Typography color='error' className={classes.flexItem}>
                          <ErrorIcon className={classes.icon} />
                          {errors.general}
                        </Typography>
                      </Grid>
                    )}
                  </Grid>
                </DialogContent>
                <DialogActions>
                  <Button onClick={handleProjectModalClose}>Avbryt</Button>

                  <Button
                    color='primary'
                    variant='contained'
                    disabled={disableForm}
                    type='submit'
                  >
                    {project.id ? 'Spara projekt' : 'Skapa projekt'}
                  </Button>
                </DialogActions>
              </div>
            </form>
          );
        }}
      </Formik>

      <ErrorMessageDialog
        isModalOpen={!!qbisErrorMessage}
        handleModalClose={() => {
          setQbisErrorMessage('');
          handleProjectModalClose();
        }}
        message={qbisErrorMessage}
      />
    </DialogWrapper>
  );
};

export default withStyles(styles)(ProjectDialog);
