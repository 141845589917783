import React, { useEffect } from 'react';

import { useRequiredContext } from './common/hooks-util';
import { AuthContext } from './contexts/auth-context';
import { OpenAPI as TellUsApiOptions } from './generated/tellus';

// Init TellUs API client.
TellUsApiOptions.BASE = process.env.REACT_APP_API_URI ?? '';

export const TellUsApiProvider: React.FC = ({ children }) => {
  const { accessTokenRef } = useRequiredContext(AuthContext);

  useEffect(() => {
    TellUsApiOptions.TOKEN = () =>
      Promise.resolve(accessTokenRef.current ?? '');
  }, [accessTokenRef]);

  return <>{children}</>;
};
