/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { FeatureVersionDto } from '../models/FeatureVersionDto';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class VersionsService {

  /**
   * @returns FeatureVersionDto OK
   * @throws ApiError
   */
  public static listVersions({
    features,
  }: {
    features?: Array<string>,
  }): CancelablePromise<Array<FeatureVersionDto>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/v2/versions',
      query: {
        'features': features,
      },
    });
  }

  /**
   * @returns FeatureVersionDto OK
   * @throws ApiError
   */
  public static setVersions({
    requestBody,
  }: {
    requestBody?: Array<FeatureVersionDto>,
  }): CancelablePromise<Array<FeatureVersionDto>> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/v2/versions',
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * @returns FeatureVersionDto OK
   * @throws ApiError
   */
  public static deleteVersion({
    feature,
  }: {
    feature: string,
  }): CancelablePromise<Array<FeatureVersionDto>> {
    return __request(OpenAPI, {
      method: 'DELETE',
      url: '/v2/versions/{feature}',
      path: {
        'feature': feature,
      },
    });
  }

}
