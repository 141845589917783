import { gql } from '@apollo/client';
import { withApollo } from '@apollo/client/react/hoc';
import React from 'react';
import { withRouter } from 'react-router-dom';

import {
  Checkbox,
  CircularProgress,
  ClickAwayListener,
  IconButton,
  InputAdornment,
  ListItem,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  Menu,
  MenuItem,
  TextField,
  Tooltip,
  Typography,
  withStyles,
} from '@material-ui/core';
import {
  Check,
  Clear,
  CloudDownload,
  CompareArrows,
  Delete,
  Edit,
  FileCopy,
  History,
  MoreVert,
  Reorder,
  RestoreFromTrash,
  Sync,
} from '@material-ui/icons';

import { getClientId } from '../common/daemon-util';
import { getColoredIcon, getTooltip } from '../common/document';
import { documentDownloadUrl } from '../common/tellus-util';
import NiceDate from '../components/nice-date';
import { AuthContext } from '../contexts/auth-context';
import { DaemonConsumer } from '../contexts/daemon-context';
import ProjectContext from '../contexts/project-context';
import { UploadConsumer } from '../contexts/upload-context';
import ImageGallery from '../pages/project/image-gallery';
import { UPDATE_IMAGE_GALLERY } from '../pages/project/image-gallery';
import DocumentHandler from './document-handler';
import QueryWrapper from './query-wrapper';

const GET_DOCUMENT = gql`
  query GetDocument($id: Int!) {
    document(id: $id) {
      id
      path
      versions {
        id
        version
        createdBy
        createdAt
        conflict
        basedOn
      }
      events {
        id
        userEmail
        userName
        type
        dateTime
      }
    }
  }
`;

const FILE_ACTION = gql`
  mutation FileAction(
    $id: Int!
    $name: String!
    $type: String!
    $version: String
    $action: FileAction
    $clientId: String!
  ) {
    sendFileAction(
      fileAction: {
        id: $id
        name: $name
        type: $type
        version: $version
        action: $action
        clientId: $clientId
      }
    )
  }
`;

const UPDATE_DOCUMENT = gql`
  mutation updateDocument(
    $id: Int!
    $filename: String!
    $projectReference: String!
    $path: String!
  ) {
    updateDocument(
      id: $id
      filename: $filename
      projectReference: $projectReference
      path: $path
    )
  }
`;

export const styles = (theme) => ({
  dataInfo: {
    marginRight: '4px',
  },
  progressButton: {
    width: '48px',
    height: '48px',
  },
  progress: {
    width: '24px',
    height: '24px',
  },
  iconButton: {
    color: 'forestgreen',
  },
  iconButtonCancel: {
    color: theme.palette.error.main,
  },
  listItem: {
    borderTop: '1px solid rgba(0,0,0,0.12)',
    paddingTop: '3px',
    paddingBottom: '3px',
  },
  flexRow: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
  },
  flexItem: {
    flex: '1 auto',
  },
  secondaryInfo: {
    alignSelf: 'center',
    color: 'rgba(0,0,0,0.54)',
    textAlign: 'right',
    marginRight: '8px',
    fontFamily: '"Helvetica", "Arial", sans-serif',
    fontSize: '10px',
  },
  docHandlerRoot: {
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'column',
  },
  listProject: {
    padding: 5,
  },
});

const eventToText = {
  FileUploadedEvent: 'Laddade upp en ny version',
  FileUploadStartedEvent: 'Påbörjade uppladdning',
  FileUploadedWithConflictEvent:
    'Laddade upp en ny version. Möjligen konflikt med tidigare version.',
  FileOpenedEvent: 'Öppnade filen',
  FileClosedEvent: 'Stängde filen',
  FileSavedEvent: 'Sparade filen',
  FileCreatedEvent: 'Skapade filen',
};

class DocumentListItem extends React.Component {
  state = {
    anchorEl: null,
    eventsAnchorEl: null,
    versionsAnchorEl: null,
    changeFilename: false,
    filetype: null,
    filenameInput: '',
    filenameExtension: '',
    openDocHandler: false,
    pdfDownloadLoading: false,
    isCopy: false,
  };

  openMenu = (event) => {
    this.setState({ anchorEl: event.currentTarget });
  };

  openEventsMenu = (event) => {
    const eventsAnchorEl = Array.from(event.currentTarget.children).find(
      (x) => x.className === 'eventsMenuAnchor'
    );
    this.setState({ eventsAnchorEl });
  };

  closeMenu = () => {
    this.setState({ anchorEl: null });
  };

  closeEventsMenu = () => {
    this.setState({ eventsAnchorEl: null });
  };

  openVersionsMenu = (e) => {
    const versionsAnchorEl = Array.from(e.currentTarget.children).find(
      (x) => x.className === 'versionsMenuAnchor'
    );
    this.setState({ versionsAnchorEl });
  };

  closeVersionsMenu = () => {
    this.setState({ versionsAnchorEl: null });
  };

  handleOpenItem = async (doc, isGallery, client, version) => {
    if (isGallery) {
      this.setState({ openDoc: doc });
    } else {
      const { id } = doc;
      const fileNameSplit = doc.filename.split('.');
      const type = fileNameSplit.pop();
      const name = fileNameSplit.join('.');

      await client.mutate({
        mutation: FILE_ACTION,
        variables: {
          id,
          name,
          type: `.${type}`,
          version: version || '',
          action: 'OPEN',
          clientId: getClientId(),
        },
      });
    }
  };

  handleChangeFilename = (doc) => {
    this.closeMenu();
    this.setState({ changeFilename: true });
    if (doc.filename === 'Bilder') {
      this.setState({ filenameInput: doc.tags, filetype: 'images' });
    } else {
      const filename = doc.filename;
      const extension = filename.substring(filename.lastIndexOf('.') + 1);
      const filenameWithoutExt = filename.replace(/\.[^/.]+$/, '');

      this.setState({
        filenameInput: filenameWithoutExt,
        filenameExtension: extension,
        filetype: 'file',
      });
    }
  };

  handleSubmit = async (
    doc,
    client,
    referenceNumber,
    isCopy,
    refetching = null
  ) => {
    const { filenameInput, filenameExtension, filetype } = this.state;
    const filenameWithoutWs = filenameInput.replace(/^\s+|\s+$/gm, '');
    const filename = `${filenameWithoutWs}.${filenameExtension}`;

    if (!referenceNumber) return null;
    if (!filenameInput) return null;
    if (filetype === 'file') {
      try {
        await client.mutate({
          mutation: UPDATE_DOCUMENT,
          variables: {
            id: doc.id,
            filename: filename,
            projectReference: referenceNumber,
            path: doc.path,
          },
          refetchQueries: () => ['Documents'],
        });
        this.closeMenu();
      } catch (error) {
        console.log(error);
      }
    } else if (filetype === 'images') {
      try {
        await client.mutate({
          mutation: UPDATE_IMAGE_GALLERY,
          variables: {
            id: doc.id,
            tags: filenameWithoutWs,
            projectReference: referenceNumber,
            path: doc.path,
          },
          refetchQueries: () => ['Documents'],
        });
        this.closeMenu();
      } catch (error) {
        console.log(error);
      }
    }

    this.setState({ changeFilename: false });
  };

  render() {
    const {
      anchorEl,
      eventsAnchorEl,
      versionsAnchorEl,
      changeFilename,
      isCopy,
      filenameInput,
    } = this.state;
    const {
      classes,
      doc,
      client,
      selected,
      goToProjectOnClick,
      history,
      showPath,
      handleSelectDocument,
      selectedDocuments,
      showCheckboxes,
      refetching,
      isFirst = false,
      handleError,
    } = this.props;
    const open = Boolean(anchorEl);
    const eventsMenuOpen = Boolean(eventsAnchorEl);
    const versionsMenuOpen = Boolean(versionsAnchorEl);

    const isGallery = doc.filename === 'Bilder';

    return (
      <AuthContext.Consumer>
        {({ accessToken }) => (
          <ProjectContext.Consumer>
            {({ project }) => {
              const referenceNumber =
                (project && project.referenceNumber) || null;
              return (
                <DaemonConsumer>
                  {({ daemonStatus, daemonSavedFile }) => {
                    const daemonOnline = daemonStatus === 'online';
                    const downloadUrl = documentDownloadUrl(
                      doc.id,
                      accessToken
                    );

                    const projectUri = `/project/${doc.projectReference}/documents`;

                    return (
                      <UploadConsumer>
                        {({ setIsBaseDropzoneActive }) => {
                          return (
                            <>
                              <ListItem
                                button={!changeFilename}
                                selected={selected}
                                component={
                                  isGallery ||
                                  daemonOnline ||
                                  goToProjectOnClick ||
                                  changeFilename
                                    ? 'li'
                                    : 'a'
                                }
                                href={
                                  isGallery || changeFilename ? '' : downloadUrl
                                }
                                onClick={() => {
                                  if (isGallery || daemonOnline) {
                                    this.handleOpenItem(
                                      doc,
                                      isGallery,
                                      client,
                                      changeFilename
                                    );
                                    if (daemonOnline && !isGallery) {
                                      setIsBaseDropzoneActive(true);
                                    } else {
                                      setIsBaseDropzoneActive(false);
                                    }
                                  }
                                  if (goToProjectOnClick) {
                                    history.push(projectUri);
                                  }
                                }}
                                style={{ paddingLeft: 10 }}
                                className={(!isFirst && classes.listItem) || ''}
                              >
                                <Tooltip title={getTooltip(doc)}>
                                  <ListItemIcon
                                    style={{ width: '20px', height: '20px' }}
                                  >
                                    {getColoredIcon(doc)}
                                  </ListItemIcon>
                                </Tooltip>
                                <ListItemText
                                  disableTypography
                                  primary={
                                    <div className={classes.flexRow}>
                                      <div className={classes.flexItem}>
                                        {changeFilename ? (
                                          <ClickAwayListener
                                            onClickAway={() =>
                                              this.setState({
                                                changeFilename: false,
                                              })
                                            }
                                          >
                                            <TextField
                                              label='Ändra filnamn'
                                              value={filenameInput || ''}
                                              onChange={(event) =>
                                                this.setState({
                                                  filenameInput:
                                                    event.target.value,
                                                })
                                              }
                                              onClick={(e) => {
                                                e.stopPropagation();
                                              }}
                                              variant='outlined'
                                              fullWidth
                                              onKeyDown={(e) => {
                                                if (e.keyCode === 13) {
                                                  if (
                                                    filenameInput &&
                                                    filenameInput.length > 0
                                                  ) {
                                                    this.handleSubmit(
                                                      doc,
                                                      client,
                                                      referenceNumber,
                                                      false
                                                    );
                                                  } else {
                                                    this.setState({
                                                      changeFilename: false,
                                                    });
                                                  }
                                                }
                                              }}
                                              inputRef={
                                                this.focusFilenameTextField
                                              }
                                              InputProps={{
                                                endAdornment: (
                                                  <>
                                                    <InputAdornment position='end'>
                                                      <IconButton
                                                        className={
                                                          classes.iconButton
                                                        }
                                                        title={'Spara ändring'}
                                                        color='primary'
                                                        onClick={() => {
                                                          if (
                                                            filenameInput &&
                                                            filenameInput.length >
                                                              0
                                                          ) {
                                                            this.handleSubmit(
                                                              doc,
                                                              client,
                                                              referenceNumber,
                                                              false
                                                            );
                                                          } else {
                                                            this.setState({
                                                              changeFilename: false,
                                                            });
                                                          }
                                                        }}
                                                      >
                                                        <Check />
                                                      </IconButton>
                                                      <IconButton
                                                        className={
                                                          classes.iconButtonCancel
                                                        }
                                                        title={'Avbryt'}
                                                        onClick={() =>
                                                          this.setState({
                                                            changeFilename: false,
                                                          })
                                                        }
                                                      >
                                                        <Clear />
                                                      </IconButton>
                                                    </InputAdornment>
                                                  </>
                                                ),
                                              }}
                                            />
                                          </ClickAwayListener>
                                        ) : (
                                          <Typography
                                            variant='body2'
                                            component='span'
                                            style={{
                                              display: 'inline-block',
                                            }}
                                          >
                                            {!isGallery
                                              ? doc.filename
                                              : `Album: ${
                                                  doc.tags ||
                                                  'Ingen titel på album'
                                                }`}
                                          </Typography>
                                        )}
                                        {daemonOnline ? (
                                          <Tooltip title='Filen kommer laddas upp av Tellus Sync'>
                                            <Sync
                                              color='secondary'
                                              style={{
                                                marginLeft: 6,
                                                verticalAlign: 'middle',
                                                width: '18px',
                                                height: '18px',
                                                fontSize: '18px',
                                              }}
                                            />
                                          </Tooltip>
                                        ) : (
                                          <Tooltip title='Tellus Sync är ej igång'>
                                            <Sync
                                              color='error'
                                              style={{
                                                marginLeft: 6,
                                                verticalAlign: 'middle',
                                                width: '18px',
                                                height: '18px',
                                                fontSize: '18px',
                                              }}
                                            />
                                          </Tooltip>
                                        )}
                                      </div>
                                      <div
                                        className={`${classes.flexItem} ${classes.secondaryInfo}`}
                                      >
                                        {showPath ? (
                                          <>
                                            <span className={classes.dataInfo}>
                                              {doc.path &&
                                                doc.path.replace(/\\/g, ' » ')}
                                            </span>
                                            <span>
                                              <NiceDate
                                                date={doc.modifiedAt}
                                                strict
                                                convertToDistance={false}
                                                dateFormat={'YYYY-MM-DD HH:mm'}
                                              />
                                            </span>
                                          </>
                                        ) : (
                                          <>
                                            <Tooltip
                                              title={doc.modifiedBy.replace(
                                                /.*<(.*)>$/,
                                                '$1'
                                              )}
                                            >
                                              <span>
                                                {doc.modifiedBy.replace(
                                                  /\s<.*$/,
                                                  ''
                                                )}
                                              </span>
                                            </Tooltip>{' '}
                                            <NiceDate
                                              date={doc.modifiedAt}
                                              strict
                                              convertToDistance={false}
                                              dateFormat={'YYYY-MM-DD HH:mm'}
                                            />
                                          </>
                                        )}
                                      </div>
                                    </div>
                                  }
                                  inset
                                />
                                <ListItemSecondaryAction>
                                  {this.props.restoreDocument ? (
                                    <IconButton
                                      onClick={() =>
                                        this.props.restoreDocument(doc.id)
                                      }
                                      className={classes.progressButton}
                                      title={'Återställ dokument'}
                                    >
                                      {!this.props.loading ? (
                                        <RestoreFromTrash />
                                      ) : (
                                        <CircularProgress
                                          style={{
                                            width: '24px',
                                            height: '24px',
                                          }}
                                        />
                                      )}
                                    </IconButton>
                                  ) : (
                                    <>
                                      {showCheckboxes ? (
                                        doc.filename === 'Bilder' ? (
                                          <div style={{ minWidth: 36 }} />
                                        ) : (
                                          <Checkbox
                                            color='primary'
                                            onChange={() =>
                                              handleSelectDocument(doc.id)
                                            }
                                            checked={
                                              selectedDocuments &&
                                              selectedDocuments.includes(doc.id)
                                            }
                                          />
                                        )
                                      ) : (
                                        <>
                                          <IconButton onClick={this.openMenu}>
                                            <MoreVert />
                                          </IconButton>
                                          <Menu
                                            id={`doc-menu-${doc.id}`}
                                            anchorEl={anchorEl}
                                            open={open}
                                            onClose={this.closeMenu}
                                          >
                                            <MenuItem
                                              key='changeName'
                                              onClick={() =>
                                                this.handleChangeFilename(doc)
                                              }
                                            >
                                              <ListItemIcon>
                                                <Edit />
                                              </ListItemIcon>
                                              Byt namn
                                            </MenuItem>
                                            {refetching && !isGallery && (
                                              <MenuItem
                                                key='copy'
                                                onClick={() =>
                                                  this.setState({
                                                    openDocHandler: true,
                                                    isCopy: true,
                                                  })
                                                }
                                              >
                                                <ListItemIcon>
                                                  <FileCopy />
                                                </ListItemIcon>
                                                Skapa kopia
                                              </MenuItem>
                                            )}
                                            {refetching && !isGallery && (
                                              <MenuItem
                                                key='changeProject'
                                                onClick={() =>
                                                  this.setState({
                                                    openDocHandler: true,
                                                  })
                                                }
                                              >
                                                <ListItemIcon>
                                                  <CompareArrows />
                                                </ListItemIcon>
                                                Flytta fil
                                              </MenuItem>
                                            )}
                                            {!isGallery ? (
                                              <MenuItem
                                                key='download'
                                                component='a'
                                                href={downloadUrl}
                                                onClick={() => {
                                                  this.closeMenu();
                                                }}
                                              >
                                                <ListItemIcon>
                                                  <CloudDownload />
                                                </ListItemIcon>
                                                Ladda ner
                                              </MenuItem>
                                            ) : (
                                              <MenuItem
                                                key='open'
                                                onClick={() => {
                                                  this.handleOpenItem(
                                                    doc,
                                                    isGallery,
                                                    client,
                                                    changeFilename
                                                  );
                                                }}
                                              >
                                                <ListItemIcon>
                                                  <CloudDownload />
                                                </ListItemIcon>
                                                Visa
                                              </MenuItem>
                                            )}
                                            {this.props.removeDocument && (
                                              <MenuItem
                                                key='remove'
                                                onClick={() => {
                                                  this.props.removeDocument(
                                                    doc.id
                                                  );
                                                  this.closeMenu();
                                                }}
                                              >
                                                <ListItemIcon>
                                                  <Delete />
                                                </ListItemIcon>
                                                Ta bort
                                              </MenuItem>
                                            )}
                                            <MenuItem
                                              key='versions'
                                              onClick={this.openVersionsMenu}
                                            >
                                              <ListItemIcon>
                                                <History />
                                              </ListItemIcon>
                                              Versioner
                                              <div className='versionsMenuAnchor' />
                                            </MenuItem>
                                            <MenuItem
                                              key='events'
                                              onClick={this.openEventsMenu}
                                            >
                                              <ListItemIcon>
                                                <Reorder />
                                              </ListItemIcon>
                                              Alla händelser
                                              <div className='eventsMenuAnchor' />
                                            </MenuItem>
                                            {(versionsMenuOpen ||
                                              eventsMenuOpen) && (
                                              <QueryWrapper
                                                query={GET_DOCUMENT}
                                                variables={{ id: doc.id }}
                                                fetchPolicy='no-cache'
                                              >
                                                {({ data, loading }) => {
                                                  if (
                                                    loading ||
                                                    !data ||
                                                    !data.document
                                                  ) {
                                                    return (
                                                      <Menu
                                                        open
                                                        anchorEl={
                                                          eventsMenuOpen
                                                            ? eventsAnchorEl
                                                            : versionsAnchorEl
                                                        }
                                                        anchorOrigin={{
                                                          vertical: 'bottom',
                                                          horizontal: 'left',
                                                        }}
                                                        transformOrigin={{
                                                          vertical: 'top',
                                                          horizontal: 'right',
                                                        }}
                                                        getContentAnchorEl={
                                                          null
                                                        }
                                                      >
                                                        <MenuItem
                                                          onClick={() => {
                                                            this.closeEventsMenu();
                                                            this.closeVersionsMenu();
                                                          }}
                                                          style={{
                                                            minWidth: '150px',
                                                          }}
                                                        >
                                                          <CircularProgress
                                                            size={18}
                                                          />
                                                        </MenuItem>
                                                      </Menu>
                                                    );
                                                  }

                                                  let menuValues =
                                                    eventsMenuOpen
                                                      ? data.document.events
                                                      : data.document.versions;

                                                  menuValues =
                                                    menuValues.reduce(
                                                      (res, val) => {
                                                        if (
                                                          !res.some(
                                                            (x) =>
                                                              x.id === val.id
                                                          )
                                                        ) {
                                                          res.push(val);
                                                        }
                                                        return res;
                                                      },
                                                      []
                                                    );

                                                  return (
                                                    <Menu
                                                      id={`${
                                                        eventsMenuOpen
                                                          ? 'events'
                                                          : 'versions'
                                                      }-menu-${doc.id}`}
                                                      anchorEl={
                                                        eventsMenuOpen
                                                          ? eventsAnchorEl
                                                          : versionsAnchorEl
                                                      }
                                                      open={
                                                        eventsMenuOpen ||
                                                        versionsMenuOpen
                                                      }
                                                      onClose={() => {
                                                        this.closeEventsMenu();
                                                        this.closeVersionsMenu();
                                                      }}
                                                      disableAutoFocusItem
                                                      PaperProps={{
                                                        style: {
                                                          maxHeight: 400,
                                                        },
                                                      }}
                                                    >
                                                      {menuValues.length ? (
                                                        menuValues
                                                          .sort((a, b) =>
                                                            eventsMenuOpen
                                                              ? a.dateTime -
                                                                b.dateTime
                                                              : a.version -
                                                                b.version
                                                          )
                                                          .slice(0, 50)
                                                          .map((x, i) => (
                                                            <MenuItem
                                                              key={`move${i}`}
                                                              style={{
                                                                display:
                                                                  'block',
                                                                height: 'auto',
                                                                paddingTop: 3,
                                                                paddingBottom: 3,
                                                              }}
                                                              title={
                                                                !eventsMenuOpen
                                                                  ? 'Klicka för att öppna denna version'
                                                                  : undefined
                                                              }
                                                              onClick={(e) => {
                                                                e.preventDefault();
                                                                if (
                                                                  !eventsMenuOpen
                                                                ) {
                                                                  this.handleOpenItem(
                                                                    doc,
                                                                    false,
                                                                    client,
                                                                    x.version
                                                                  );
                                                                }
                                                              }}
                                                            >
                                                              <Typography>
                                                                {eventsMenuOpen
                                                                  ? x.userName
                                                                  : x.createdBy}
                                                              </Typography>
                                                              <Typography variant='caption'>
                                                                {eventsMenuOpen
                                                                  ? eventToText[
                                                                      x.type
                                                                    ]
                                                                  : 'Uppladdad'}{' '}
                                                                <NiceDate
                                                                  date={
                                                                    eventsMenuOpen
                                                                      ? x.dateTime
                                                                      : x.createdAt
                                                                  }
                                                                  strict
                                                                  convertToDistance={
                                                                    false
                                                                  }
                                                                />
                                                              </Typography>
                                                            </MenuItem>
                                                          ))
                                                      ) : (
                                                        <MenuItem
                                                          onClick={() => {
                                                            this.closeEventsMenu();
                                                            this.closeVersionsMenu();
                                                          }}
                                                        >
                                                          {`Inga ${
                                                            eventsMenuOpen
                                                              ? 'händelser'
                                                              : 'versioner'
                                                          }`}
                                                        </MenuItem>
                                                      )}
                                                    </Menu>
                                                  );
                                                }}
                                              </QueryWrapper>
                                            )}
                                          </Menu>
                                        </>
                                      )}
                                    </>
                                  )}
                                </ListItemSecondaryAction>
                              </ListItem>
                              {this.state.openDoc && (
                                <ImageGallery
                                  open
                                  projectReference={referenceNumber}
                                  title={this.state.openDoc.tags}
                                  galleryDocumentId={this.state.openDoc.id}
                                  onClose={() =>
                                    this.setState(
                                      { openDoc: null },
                                      setIsBaseDropzoneActive(true)
                                    )
                                  }
                                  doc={doc}
                                  isGallery={isGallery}
                                  client={client}
                                />
                              )}
                              {this.state.openDocHandler && (
                                <DocumentHandler
                                  open
                                  client={client}
                                  doc={doc}
                                  isCopy={isCopy}
                                  onClose={(error) => {
                                    this.setState({
                                      openDocHandler: false,
                                      anchorEl: null,
                                    });
                                    handleError && handleError(error);
                                  }}
                                  refetching={refetching}
                                />
                              )}
                            </>
                          );
                        }}
                      </UploadConsumer>
                    );
                  }}
                </DaemonConsumer>
              );
            }}
          </ProjectContext.Consumer>
        )}
      </AuthContext.Consumer>
    );
  }
}

export default withApollo(withStyles(styles)(withRouter(DocumentListItem)));
