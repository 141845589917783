/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { BlastDto } from '../models/BlastDto';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class BlastService {

  /**
   * @returns BlastDto OK
   * @throws ApiError
   */
  public static getJournalDocument({
    referenceNumber,
    blastId,
  }: {
    referenceNumber: string,
    blastId: number,
  }): CancelablePromise<BlastDto> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/v2/projects/{referenceNumber}/blasts/{blastId}',
      path: {
        'referenceNumber': referenceNumber,
        'blastId': blastId,
      },
    });
  }

  /**
   * @returns any OK
   * @throws ApiError
   */
  public static downloadJournalDocument({
    blastId,
    referenceNumber,
  }: {
    blastId: number,
    referenceNumber: string,
  }): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/v2/projects/{referenceNumber}/blasts/{blastId}/journal',
      path: {
        'blastId': blastId,
        'referenceNumber': referenceNumber,
      },
    });
  }

  /**
   * @returns number OK
   * @throws ApiError
   */
  public static uploadJournalDocument({
    blastId,
    referenceNumber,
    formData,
  }: {
    blastId: number,
    referenceNumber: string,
    formData?: {
      file: Blob;
    },
  }): CancelablePromise<number> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/v2/projects/{referenceNumber}/blasts/{blastId}/journal',
      path: {
        'blastId': blastId,
        'referenceNumber': referenceNumber,
      },
      formData: formData,
      mediaType: 'multipart/form-data',
    });
  }

}
