import { Promise } from 'bluebird';
import React, { useCallback, useReducer, useState } from 'react';
import SparkMD5 from 'spark-md5';
import uuid from 'uuid/v4';

import { Snackbar, Typography } from '@material-ui/core';

import { useRequiredContext } from '../common/hooks-util';
import { BlastService } from '../generated/tellus';
import { AuthContext } from './auth-context';

const UploadContext = React.createContext({});

const calculateChecksum = (file) => {
  const chunkSize = 2 * 1024 * 1024; // Use 2MiB chunks
  const chunks = Math.ceil(file.size / chunkSize);
  let currentChunk = 0;
  const spark = new SparkMD5.ArrayBuffer();
  const reader = new FileReader();

  return new Promise((resolve) => {
    const loadNext = () => {
      if (currentChunk >= chunks) {
        resolve(spark.end());
        return;
      }

      const start = currentChunk * chunkSize;
      const end = Math.min(start + chunkSize, file.size);
      file.slice();
      reader.readAsArrayBuffer(file.slice(start, end));
      currentChunk++;
    };

    reader.onload = (e) => {
      spark.append(e.target.result);
      loadNext();
    };

    loadNext();
  });
};

const initialState = {
  uploads: [],
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'startUpload': {
      action.payload.done = 0;
      return { ...state, uploads: [...state.uploads, action.payload] };
    }
    case 'fileUploaded': {
      const uploads = state.uploads.map((upload) => {
        if (upload.uploadId !== action.payload.uploadId) return upload;

        return {
          ...upload,
          numberOfFiles: upload.numberOfFiles,
          done: upload.done + 1,
        };
      });

      return { ...state, uploads };
    }
    case 'uploadDone': {
      const uploads = state.uploads.filter(
        (x) => x.uploadId !== action.payload.uploadId
      );
      return { ...state, uploads };
    }
    default:
      return state;
  }
};

const uploadJournal = (projectReference, blastId, file) => {
  return BlastService.uploadJournalDocument({
    blastId: blastId,
    referenceNumber: projectReference,
    formData: {
      file: file,
    },
  });
};

export const UploadProvider = ({ children }) => {
  const { accessTokenRef } = useRequiredContext(AuthContext);

  const [state, dispatch] = useReducer(reducer, initialState);
  const [isBaseDropzoneActive, setIsBaseDropzoneActive] = useState(true);

  const getIdForBlast = useCallback(
    async (blobId) => {
      const response = await fetch(
        `${process.env.REACT_APP_API_URI}document/getDocumentId?id=${blobId}&auth=${accessTokenRef.current}`,
        {
          method: 'GET',
        }
      );
      return await response.text();
    },
    [accessTokenRef]
  );

  const clearDocEntry = useCallback(
    async (blobId) => {
      await fetch(
        `${process.env.REACT_APP_API_URI}document/clearDoc?blobId=${blobId}
    &auth=${accessTokenRef.current}`,
        {
          method: 'POST',
        }
      );
    },
    [accessTokenRef]
  );

  const uploadFile = useCallback(
    async (
      uploadId,
      path,
      projectReference,
      isAlbum,
      file,
      journalDocument
    ) => {
      const { name: filename, type: contentType } = file;
      let checksum = '';
      let blobId;

      try {
        // If it is not an album upload as blob with checksum
        if (!isAlbum) {
          checksum = await calculateChecksum(file);
        }

        // Create document in tellus database
        const fileResponse = await fetch(
          `${process.env.REACT_APP_API_URI}document/createFile?auth=${accessTokenRef.current}`,
          {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
              tags: '',
              path,
              uploadId,
              projectReference,
              filename,
              contentType,
              isAlbum,
              checksum,
            }),
          }
        );

        blobId = await fileResponse.text();

        // Get s3 presigned link
        const response = await fetch(
          `${
            process.env.REACT_APP_API_URI
          }document/getUploadUrl?isAlbum=${isAlbum}&projectReference=${projectReference}&uploadId=${uploadId}&filename=${encodeURIComponent(
            filename
          )}&blobId=${blobId}&auth=${accessTokenRef.current}`
        );

        const uploadUrl = await response.json();
        let s3Response;
        // Upload file to s3
        if (journalDocument) {
          s3Response = await fetch(uploadUrl.url, {
            method: 'PUT',
            body: file,
          });
          if (!s3Response || !s3Response.ok) {
            await clearDocEntry(blobId);
            return s3Response;
          }

          return getIdForBlast(blobId);
        }

        s3Response = await fetch(uploadUrl.url, {
          method: 'PUT',
          body: file,
        });

        if (!s3Response || !s3Response.ok) {
          await clearDocEntry(blobId);
        }

        return s3Response;
      } catch (error) {
        //to clean the document and blob entry if the file upload is falied.
        await clearDocEntry(blobId);

        console.log(error);
        return null;
      }
    },
    [accessTokenRef, clearDocEntry, getIdForBlast]
  );

  const createAlbum = useCallback(
    async (uploadId, path, projectReference, parentDocument, tagName) => {
      return await fetch(
        `${process.env.REACT_APP_API_URI}document/createAlbum?auth=${accessTokenRef.current}`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            tags: tagName ?? '',
            path,
            uploadId,
            projectReference,
            parentDocument,
          }),
        }
      );
    },
    [accessTokenRef]
  );

  const uploadFiles = useCallback(
    async (
      setError,
      refetch,
      project,
      path,
      files,
      journalDocument,
      uploadIdForAlbum,
      parentDocumentForAlbum,
      tagName,
      blastId
    ) => {
      let uploadId;

      if (!uploadIdForAlbum) {
        uploadId = uuid();
      } else {
        uploadId = uploadIdForAlbum;
      }

      dispatch({
        type: 'startUpload',
        payload: {
          projectName: project.name,
          uploadId,
          path,
          numberOfFiles: files.length,
        },
      });

      const isAlbum =
        (files.length > 1 && files.every((f) => f.type.startsWith('image'))) ||
        parentDocumentForAlbum !== undefined;

      if (isAlbum) {
        await createAlbum(
          uploadId,
          path,
          project.referenceNumber,
          parentDocumentForAlbum,
          tagName
        );
      }

      if (journalDocument) {
        const documentIdForBlast = await Promise.map(
          files,
          async (file) => {
            const result = await uploadJournal(
              project.referenceNumber,
              blastId,
              file
            );

            dispatch({
              type: 'fileUploaded',
              payload: {
                uploadId,
              },
            });

            return result;
          },
          { concurrency: 4 }
        );
        setTimeout(() => {
          dispatch({
            type: 'uploadDone',
            payload: {
              uploadId,
            },
          });
        }, 2000);

        return documentIdForBlast;
      } else {
        let data = await Promise.map(
          files,
          async (file) => {
            const result = await uploadFile(
              uploadId,
              path,
              project.referenceNumber,
              isAlbum,
              file
            );

            dispatch({
              type: 'fileUploaded',
              payload: {
                uploadId,
              },
            });

            return result;
          },
          { concurrency: 4 }
        );

        !!refetch && refetch();

        setTimeout(() => {
          dispatch({
            type: 'uploadDone',
            payload: {
              uploadId,
            },
          });

          !!setError &&
            setError(
              !data ||
                data.length <= 0 ||
                data.filter((r) => !r || !r.ok).length > 0
            );
        }, 2000);
      }
    },
    [createAlbum, uploadFile]
  );

  return (
    <>
      <UploadContext.Provider
        value={{
          uploadFiles,
          getIdForBlast,
          state,
          dispatch,
          isBaseDropzoneActive,
          setIsBaseDropzoneActive,
        }}
      >
        {children}
      </UploadContext.Provider>
      {state.uploads.length > 0 && (
        <Snackbar
          style={{
            top: 75,
          }}
          message={
            <div>
              <Typography
                variant='h6'
                color='inherit'
                style={{ color: 'white' }}
              >
                Uppladdning filer
              </Typography>
              {state.uploads.map((upload, i) => (
                <div
                  key={i}
                  style={{ display: 'flex', justifyContent: 'space-between' }}
                >
                  <span>{`${upload.projectName}`}</span>{' '}
                  <span>
                    {upload.done === upload.numberOfFiles
                      ? 'Klart!'
                      : `${upload.done}/${upload.numberOfFiles}`}
                  </span>
                </div>
              ))}
            </div>
          }
          open
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
        />
      )}
    </>
  );
};

export const UploadConsumer = UploadContext.Consumer;
