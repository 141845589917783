import uuid from 'uuid/v4';

export const getClientId = () => {
  let clientId = localStorage.getItem('clientId');

  if (!clientId) {
    clientId = uuid();
    localStorage.setItem('clientId', clientId);
  }

  return clientId;
};
