import { useCallback, useMemo, useState } from 'react';
import { Route } from 'react-router-dom';

import {
  Divider,
  Icon,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from '@material-ui/core';
import withWidth, { isWidthDown } from '@material-ui/core/withWidth';

import { useRequiredContext } from '../../common/hooks-util';
import MenuItem from '../../components/navigation/menu-item';
import { UserContext } from '../../contexts/user-context';
import { AdminPolicy } from '../policies';
import globalRoutes from '../routes';
import { ProjectMap } from './map/project-map';
import routes from './routes';

const ProjectMenuItems = ({ closeDrawer, projectContext, width }) => {
  const { role } = useRequiredContext(UserContext);

  const [openProjectMap, setOpenProjectMap] = useState(false);

  const showRoute = useCallback(
    (route) => {
      // Always hide hidden routes.
      if (route.hidden) {
        return false;
      }

      // Always show routes without role requirements.
      if (!route.allowedRoles) {
        return true;
      }

      // Hide routes where the user don't have the required role.
      if (!route.allowedRoles.includes(role)) {
        return false;
      }

      // Blast routes are only visible to admins and non property owner.
      return (
        !route.isBlastRoute ||
        AdminPolicy.includes(role) ||
        !projectContext.project?.isPropertyOwner
      );
    },
    [projectContext.project?.isPropertyOwner, role]
  );

  const small = useMemo(() => isWidthDown('sm', width), [width]);

  return (
    <>
      <List>
        {small
          ? globalRoutes
              .filter((r) => !r.hidden)
              .map(
                (r) =>
                  showRoute(r) && (
                    <MenuItem
                      key={r.name}
                      to={r.path}
                      icon={r.icon}
                      name={r.name}
                      exact={r.exact}
                      closeDrawer={closeDrawer}
                    />
                  )
              )
          : null}
      </List>
      {projectContext?.project && (
        <Route
          path='/project/:project'
          render={({ match }) => (
            <List disablePadding>
              {small && (
                <div>
                  <li>
                    <Divider variant='fullWidth' />
                  </li>
                  <ListItem>
                    <ListItemIcon>
                      <Icon>arrow_drop_down</Icon>
                    </ListItemIcon>
                    <ListItemText>Projekt</ListItemText>
                  </ListItem>
                </div>
              )}

              {routes.map((r) => {
                const { nameInPath, name, icon, exact } = r;
                return (
                  showRoute(r) && (
                    <MenuItem
                      key={nameInPath}
                      to={`/project/${match.params.project}${
                        nameInPath ? '/' + nameInPath : ''
                      }`}
                      exact={exact}
                      icon={icon}
                      name={name}
                      closeDrawer={closeDrawer}
                      inset={small}
                    />
                  )
                );
              })}
              <ListItem
                button
                onClick={() => {
                  closeDrawer?.();
                  setOpenProjectMap(true);
                }}
              >
                <ListItemIcon>
                  <Icon>location_on</Icon>
                </ListItemIcon>
                <ListItemText inset primary='Visa karta' />
              </ListItem>
              {projectContext?.project != null && (
                <ProjectMap
                  projectNumber={projectContext?.project.referenceNumber}
                  open={openProjectMap}
                  onClose={() => setOpenProjectMap(false)}
                />
              )}
            </List>
          )}
        />
      )}
    </>
  );
};

export default withWidth()(ProjectMenuItems);
