import { lazy } from 'react';

import { AdminPolicy, ContactPersonPolicy, SuperAdminPolicy } from './policies';

const Home = lazy(() => import('./home'));
const Login = lazy(() => import('./login'));
const Project = lazy(() => import('./project'));
const Version = lazy(() => import('./version'));
const Dashboard = lazy(() => import('./dashboard'));
const Users = lazy(() => import('./users'));
const Incoming = lazy(() => import('./incoming'));
const Customers = lazy(() => import('./customers'));
const Gdpr = lazy(() => import('./gdpr'));
const PrepareInvoice = lazy(() => import('./prepare-invoice'));
const Faq = lazy(() => import('./faq'));
const Monitor = lazy(() => import('./monitor'));
const measuringDataChart = lazy(() => import('./measuring-data-chart'));
const Instruments = lazy(() => import('./instruments'));

const menuItems = [
  {
    path: '/',
    component: Home,
    name: 'Hem',
    logo: 'TellUs',
    icon: 'home',
    exact: true,
    allowedRoles: ContactPersonPolicy,
  },
  {
    path: '/login',
    component: Login,
    name: 'Logga in',
    hidden: true,
    hideHeader: true,
  },
  {
    path: '/version',
    component: Version,
    name: 'Tellus Metadata',
    icon: 'settings',
    hidden: true,
    allowedRoles: AdminPolicy,
  },
  {
    path: '/dashboard',
    component: Dashboard,
    name: 'Dashboard',
    icon: 'dashboard',
    allowedRoles: AdminPolicy,
  },
  {
    path: '/users',
    component: Users,
    name: 'Användare',
    icon: 'people',
    allowedRoles: AdminPolicy,
  },
  {
    path: '/customers',
    component: Customers,
    name: 'Kunder',
    icon: 'assignment_ind',
    allowedRoles: AdminPolicy,
  },
  {
    path: '/incoming',
    component: Incoming,
    name: 'Inkommande',
    icon: 'smartphone',
    allowedRoles: AdminPolicy,
  },
  {
    path: '/prepare-invoice',
    component: PrepareInvoice,
    name: 'Förbered fakturor',
    icon: 'receipt',
    allowedRoles: SuperAdminPolicy,
  },
  {
    path: '/instruments',
    component: Instruments,
    name: 'Instrument',
    icon: 'dashboard',
    allowedRoles: SuperAdminPolicy,
  },
  {
    path: '/project/:referenceNumber',
    component: Project,
    name: 'Projekt',
    icon: 'description',
    hasMenu: true,
    allowedRoles: ContactPersonPolicy,
  },
  {
    path: '/measuringData/:referenceNumber/:measuringDataId',
    component: measuringDataChart,
    name: 'measuringDataChart',
    hideHeader: true,
    hidden: true,
    allowedRoles: ContactPersonPolicy,
  },
  {
    path: '/gdpr',
    component: Gdpr,
    name: 'GDPR',
    allowedRoles: SuperAdminPolicy,
    hidden: true,
  },
  {
    path: '/faq',
    component: Faq,
    name: 'FAQ',
    allowedRoles: ContactPersonPolicy,
    hidden: true,
  },
  {
    path: '/monitor',
    component: Monitor,
    name: 'Monitor',
    allowedRoles: AdminPolicy,
    hidden: true,
  },
];

export default menuItems;
