import { gql, useApolloClient } from '@apollo/client';
import { useContext, useEffect, useRef, useState } from 'react';
import Skeleton from 'react-loading-skeleton';
import { withRouter } from 'react-router-dom';

import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
} from '@material-ui/core';

import { useRequiredContext } from '../common/hooks-util';
import DialogWrapper from '../components/dialog-wrapper';
import { AuthContext } from '../contexts/auth-context';
import ProjectContext from '../contexts/project-context';
import Documents from '../pages/project/documents/documents';
import AutoComplete from './auto-complete';
import HighlightedFieldset from './highlighted-fieldset';
import QueryWrapper from './query-wrapper';

const GET_PROJECTS = gql`
  query Projects {
    projects(first: 2000) {
      edges {
        node {
          id
          referenceNumber
          name
        }
      }
    }
  }
`;

function DocumentHandler({ doc, open, onClose, refetching, isCopy }) {
  const project = useContext(ProjectContext);
  const projectAnchorEl = useRef(null);
  const [projectReference, setProjectReference] = useState(null);
  const [projectName, setProjectName] = useState(null);
  const [filePath, setFilePath] = useState(null);
  const [loading, setLoading] = useState(false);

  const client = useApolloClient();

  const { accessTokenRef } = useRequiredContext(AuthContext);

  useEffect(() => {
    const initialFileSettings = (doc) => {
      !projectReference && setProjectReference(project.project.referenceNumber);
      !projectName && setProjectName(project.project.name);
      setFilePath(doc.path);
    };
    initialFileSettings(doc);
  }, [doc, project, projectName, projectReference]);

  const handleChange = (e) => {
    setProjectReference(e.target.value);
    setProjectName(e.target.secondaryValue);
  };

  const handlePathChange = (path) => {
    setFilePath(path);
  };

  const moveFile = async (doc, internalMove) => {
    const moveUri = `${process.env.REACT_APP_API_URI}document/${
      internalMove ? 'updatePath' : 'move'
    }/${doc.id}?auth=${accessTokenRef.current}`;
    const data = new FormData();
    data.append('projectReference', projectReference);
    data.append('originalProjectReference', project.project.referenceNumber);
    if (!!filePath) data.append('path', filePath);

    const response = await fetch(moveUri, {
      method: 'POST',
      body: data,
    });

    if (response.ok) onClose && onClose();
    else
      onClose &&
        onclose(
          response.ok
            ? null
            : response.status === 400
            ? await response.text()
            : 'server internal error'
        );
    setFilePath(null);
  };

  const copyFile = async (doc, copyFilename, referenceNumber) => {
    const copyUri = `${process.env.REACT_APP_API_URI}document/copy/${doc.id}?auth=${accessTokenRef.current}`;

    const data = new FormData();
    data.append('projectReference', referenceNumber);
    data.append('filename', copyFilename);
    if (!!filePath) data.append('path', filePath);

    const response = await fetch(copyUri, {
      method: 'POST',
      body: data,
    });

    if (response.ok) onClose && onClose();
    else
      onClose &&
        onClose(
          response.status === 400
            ? await response.text()
            : 'server internal error'
        );
    setFilePath(null);
  };

  const handleSubmit = async (doc) => {
    try {
      setLoading(true);
      if (isCopy) {
        const copyName =
          doc.filename !== 'Bilder'
            ? `Kopia_${doc.filename}`
            : `Kopia_Album: ${doc.tags || 'Ingen titel på album'}`;
        await copyFile(doc, copyName, projectReference);
      } else {
        const internalMove =
          projectReference === project.project.referenceNumber;
        await moveFile(doc, internalMove);
      }
      refetching();
    } finally {
      setLoading(false);
    }
  };

  return (
    <QueryWrapper query={GET_PROJECTS} errorMessageProps={{ paper: true }}>
      {({ loading: projectsLoading, data }) => (
        <DialogWrapper
          open={open}
          onClose={() => onClose?.()}
          loading={projectsLoading || loading}
          aria-labelledby='gallery-dialog'
        >
          <DialogTitle>{isCopy ? 'Kopiera' : 'Flytta'} fil</DialogTitle>
          <HighlightedFieldset
            mode='info'
            icon='help'
            title={isCopy ? 'Kopiera filen' : 'Flytta filen'}
            text={`${
              isCopy ? 'Kopiera' : 'Flytta'
            } en fil genom att navigera i filstrukturen nedan till den nya önskade platsen. Filen kan ${
              isCopy ? 'kopieras' : 'flyttas'
            } till ett annat projekt genom att ändra valt projekt nedan. Sök på namn eller projektnummer`}
          />
          <DialogContent>
            <Grid container spacing={2} style={{ paddingTop: 15, height: 390 }}>
              <Grid item xs={12}>
                <div ref={projectAnchorEl}>
                  <div style={{ marginTop: 10 }}>
                    <div ref={projectAnchorEl}>
                      {loading || projectsLoading ? (
                        <Skeleton />
                      ) : (
                        <AutoComplete
                          id='project-autocomplete'
                          label='Sök och välj projekt'
                          placeholder='Projektnamn'
                          items={data.projects.edges.map((p) => ({
                            id: p.node.referenceNumber,
                            name: `${p.node.name} - ${p.node.referenceNumber}`,
                            referenceNumber: p.node.referenceNumber,
                          }))}
                          name='project'
                          color='primary'
                          initialSelectedItem={`${projectName} - ${projectReference}`}
                          onChange={(e) => handleChange(e)}
                          anchorEl={projectAnchorEl.current}
                        />
                      )}
                    </div>
                  </div>
                </div>
              </Grid>
              <Grid item xs={12}>
                <Documents
                  match={{
                    ...{},
                    params: {
                      referenceNumber: project.project.referenceNumber,
                    },
                  }}
                  isMoveOrCopyView
                  handlePathChange={handlePathChange}
                  client={client}
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => onClose?.()}>Avbryt</Button>
            <Button
              onClick={() => handleSubmit(doc)}
              color='primary'
              variant='contained'
              disabled={loading}
            >
              Spara
            </Button>
          </DialogActions>
        </DialogWrapper>
      )}
    </QueryWrapper>
  );
}

export default withRouter(DocumentHandler);
