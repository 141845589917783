export const documentDownloadUrl = (
  documentId: number,
  accessToken: string | null | undefined
) => {
  return `${process.env.REACT_APP_API_URI}document/${documentId}?auth=${accessToken}`;
};

export const journalDownloadUrl = (
  blastId: number,
  referenceNumber: string,
  accessToken: string | null | undefined
) => {
  return `${process.env.REACT_APP_API_URI}v2/projects/${referenceNumber}/blasts/${blastId}/journal?auth=${accessToken}`;
};
