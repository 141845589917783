import { gql } from '@apollo/client';
import { useContext } from 'react';

import {
  Button,
  Grid,
  List,
  Paper,
  Typography,
  makeStyles,
} from '@material-ui/core';

import EmptyListDrawing from '../../components/empty-list-drawing';
import QueryWrapper from '../../components/query-wrapper';
import { UserContext } from '../../contexts/user-context';
import ProjectListItem from './project-list-item';

export const useStyles = makeStyles(() => ({
  list: {
    padding: 0,
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    paddingBottom: 10,
    alignItems: 'center',
  },
  button: {
    marginTop: 5,
  },
}));

export const GET_MEASURING_CARDS_AND_POINTS = gql`
  query Project($projectReference: String) {
    project(referenceNumber: $projectReference) {
      id
      startDate
      endDate
      projectUsers {
        notificationFlag
        notificationPoints
      }
      measuringCards {
        id
        instrument {
          id
          name
          model
        }
        instrumentBattery
        instrumentCapacity
        lastTriggerActivity
        lastStatusActivity
        validSetup
        validFrom
        validTo
        allChannelsOK
        channels {
          id
          measuringPointId
        }
      }
      measuringPoints {
        id
        number
        address
        fixedBenchmark
        noBenchmark
        unknownBenchmark
      }
    }
  }
`;

const ProjectList = ({
  title,
  query,
  projectsNameInObject,
  numberOfSkeletons,
  newButtonText,
  newButtonCallback,
}) => {
  const classes = useStyles();
  const user = useContext(UserContext);

  return (
    <Grid item xs={12} sm={6} md={5}>
      <QueryWrapper
        query={query}
        errorMessageProps={{ paper: true }}
        fetchPolicy='network-only'
      >
        {({ loading, data, fetchMore }) => {
          const empty = !loading && !data[projectsNameInObject].edges.length;

          if (loading) return <></>;
          return (
            <>
              {empty ? (
                projectsNameInObject === 'favouriteProjects' ? (
                  <EmptyListDrawing
                    title={'Favoritprojekt'}
                    text={[
                      'Du kan favoritmarkera de projekt du',
                      'oftast använder genom att klicka på stjärnan',
                    ]}
                    svgPath={'organizing-projects.svg'}
                  />
                ) : (
                  projectsNameInObject === 'projects' && (
                    <EmptyListDrawing
                      title={'Projekt'}
                      text={[
                        'Det verkar inte som att du har tillgång till några projekt.',
                        'Kontakta en anställd på Metron för att få tillgång',
                      ]}
                      svgPath={'organizing-projects.svg'}
                    />
                  )
                )
              ) : (
                ''
              )}
              {!empty && (
                <>
                  <div className={classes.header}>
                    <div
                      style={{
                        display: 'flex',
                        width: '100%',
                        justifyContent: 'space-between',
                      }}
                    >
                      <Typography
                        variant='h6'
                        gutterBottom
                        color='textSecondary'
                      >
                        {title}
                      </Typography>
                      {((user && user.role === 'Admin') ||
                        user.role === 'SuperAdmin') &&
                        newButtonText && (
                          <Button
                            variant='outlined'
                            onClick={newButtonCallback}
                          >
                            {newButtonText}
                          </Button>
                        )}
                    </div>
                  </div>

                  <Paper>
                    <List component='nav' className={classes.list}>
                      {loading &&
                        Array.from(Array(numberOfSkeletons)).map((_, i) => (
                          <ProjectListItem
                            project={{ id: i, loading }}
                            key={i}
                          />
                        ))}
                      {!loading &&
                        data[projectsNameInObject].edges.map((edge) => (
                          <QueryWrapper
                            query={GET_MEASURING_CARDS_AND_POINTS}
                            errorMessageProps={{ paper: true }}
                            variables={{
                              projectReference: edge.node.referenceNumber,
                            }}
                            key={edge.node.id}
                          >
                            {({ loading, data }) => {
                              return (
                                <ProjectListItem
                                  project={edge.node}
                                  loading={loading}
                                  data={data}
                                />
                              );
                            }}
                          </QueryWrapper>
                        ))}
                    </List>
                  </Paper>
                  <Grid container justifyContent='center'>
                    {data[projectsNameInObject] &&
                    data[projectsNameInObject].pageInfo.hasNextPage ? (
                      <Button
                        onClick={() => {
                          fetchMore({
                            variables: {
                              cursor:
                                data[projectsNameInObject].pageInfo.endCursor,
                            },
                            updateQuery: (
                              previousResult,
                              { fetchMoreResult }
                            ) => {
                              const newEdges =
                                fetchMoreResult[projectsNameInObject].edges;
                              const pageInfo =
                                fetchMoreResult[projectsNameInObject].pageInfo;

                              return newEdges.length
                                ? {
                                    [projectsNameInObject]: {
                                      __typename:
                                        previousResult[projectsNameInObject]
                                          .__typename,
                                      edges: [
                                        ...previousResult[projectsNameInObject]
                                          .edges,
                                        ...newEdges,
                                      ],
                                      pageInfo,
                                    },
                                  }
                                : previousResult;
                            },
                          });
                        }}
                        size='small'
                        className={classes.button}
                      >
                        Ladda fler
                      </Button>
                    ) : null}
                  </Grid>
                </>
              )}
            </>
          );
        }}
      </QueryWrapper>
    </Grid>
  );
};

export default ProjectList;
