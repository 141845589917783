import { Context, useContext } from 'react';

export const useRequiredContext = <T>(context: Context<T>): NonNullable<T> => {
  const value = useContext(context);

  if (value == null) {
    throw new Error(`Context "${context.displayName ?? 'unknown'}" not set`);
  }

  return value;
};
