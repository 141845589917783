import {
  LayerProps,
  createTileLayerComponent,
  updateGridLayer,
  withPane,
} from '@react-leaflet/core';
import { Coords, DoneCallback, TileLayer, TileLayerOptions } from 'leaflet';

const updateAccessToken = (
  layer: TileLayerImpl,
  props: LantmaterietTileLayerProps,
  prevProps: LantmaterietTileLayerProps
) => {
  layer.options.accessToken = props.accessToken;
  updateGridLayer(layer, props, prevProps);
};

export interface LantmaterietTileLayerProps
  extends TileLayerOptions,
    LayerProps {
  url?: string;
}

export const LantmaterietTileLayer = createTileLayerComponent<
  TileLayerImpl,
  LantmaterietTileLayerProps
>(
  ({ url, ...options }, context) => ({
    instance: new TileLayerImpl(url ?? '', withPane(options, context)),
    context,
  }),
  updateAccessToken
);
LantmaterietTileLayer.defaultProps = {
  url: `${process.env.REACT_APP_API_URI}Map/Tile/3006/{z}/{y}/{x}`,
  attribution: '&copy; <a href="https://lantmateriet.se/">Lantmäteriet</a>',
  maxNativeZoom: 13,
};

class TileLayerImpl extends TileLayer {
  protected createTile(coords: Coords, done: DoneCallback): HTMLElement {
    const tile = document.createElement('img');

    const url = new URL(this.getTileUrl(coords), window.location.origin);

    const token = this.options.accessToken;
    if (token != null) {
      url.searchParams.set('auth', token);
    }

    tile.src = url.toString();

    tile.onload = () => done(undefined, tile);

    return tile;
  }
}
