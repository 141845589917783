import 'cal-heatmap/cal-heatmap.css';
import 'chartist-plugin-tooltips-updated/dist/chartist-plugin-tooltip.css';
import 'chartist/dist/chartist.css';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import WebFont from 'webfontloader';

import CssBaseline from '@material-ui/core/CssBaseline';

import { ApolloClientProvider } from './apollo-client-provider';
import { AppInsightsProvider } from './app-insights-provider';
import { MetronThemeProvider } from './metron-theme-provider';
import { MsalAuthProvider } from './msal-auth-provider';
import Index from './pages/index';
import { UpdateNotification } from './pages/update-notification';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import './style.css';
import { TellUsApiProvider } from './tellus-api-provider';
import { UserProvider } from './user-provider';
import { setYupLocale } from './yup-locale';

const config = {
  google: {
    families: ['Roboto:300,400,500', 'Archivo Narrow'],
  },
};

WebFont.load(config);

setYupLocale();

ReactDOM.render(
  <AppInsightsProvider>
    <MsalAuthProvider>
      <TellUsApiProvider>
        <ApolloClientProvider>
          <UserProvider>
            <BrowserRouter>
              <MetronThemeProvider>
                <CssBaseline />
                <UpdateNotification />
                <Index />
              </MetronThemeProvider>
            </BrowserRouter>
          </UserProvider>
        </ApolloClientProvider>
      </TellUsApiProvider>
    </MsalAuthProvider>
  </AppInsightsProvider>,
  document.getElementById('root')
);

serviceWorkerRegistration.register();
