import { gql } from '@apollo/client';
import React, { useCallback, useMemo, useState } from 'react';

import { Grid } from '@material-ui/core';

import { useRequiredContext } from '../../common/hooks-util';
import { UserContext } from '../../contexts/user-context';
import { AdminPolicy } from '../policies';
import ProjectDialog from './project-dialog';
import ProjectList from './project-list';

export const GET_PROJECTS = gql`
  query Projects($cursor: String, $first: Int = 10) {
    projects(first: $first, after: $cursor) {
      edges {
        node {
          id
          name
          referenceNumber
          isFavourite
          county
          startDate
          endDate
          manageBlasts
          customer {
            id
            name
          }
          office {
            id
            name
          }
        }
      }
      pageInfo {
        endCursor
        hasNextPage
      }
    }
  }
`;

export const GET_FAV_PROJECTS = gql`
  query FavouriteProjects($cursor: String, $first: Int = 20) {
    favouriteProjects(first: $first, after: $cursor) {
      edges {
        node {
          id
          name
          referenceNumber
          isFavourite
          county
          startDate
          endDate
          manageBlasts
          customer {
            id
            name
          }
          office {
            id
            name
          }
        }
      }
      pageInfo {
        endCursor
        hasNextPage
      }
    }
  }
`;

export const FAVOURITE_PROJECT = gql`
  mutation FavouriteProject($id: Int!) {
    favouriteProject(id: $id)
  }
`;

export const UNFAVOURITE_PROJECT = gql`
  mutation UnfavouriteProject($id: Int!) {
    unfavouriteProject(id: $id)
  }
`;

const Home = () => {
  const { role } = useRequiredContext(UserContext);

  const isAdmin = useMemo(() => AdminPolicy.includes(role), [role]);

  const [isProjectModalOpen, setIsProjectModalOpen] = useState(false);

  const handleProjectModalOpen = useCallback(
    () => setIsProjectModalOpen(true),
    []
  );
  const handleProjectModalClose = useCallback(
    () => setIsProjectModalOpen(false),
    []
  );

  return (
    <Grid container spacing={3} justifyContent='center'>
      <ProjectList
        query={GET_FAV_PROJECTS}
        title='Favoritprojekt'
        projectsNameInObject='favouriteProjects'
        numberOfSkeletons={3}
      />
      <ProjectList
        query={GET_PROJECTS}
        title='Senaste projekten'
        projectsNameInObject='projects'
        numberOfSkeletons={10}
        newButtonText='Nytt projekt'
        newButtonCallback={handleProjectModalOpen}
      />
      {isAdmin && (
        <ProjectDialog
          isProjectModalOpen={isProjectModalOpen}
          handleProjectModalClose={handleProjectModalClose}
          refetchLists={[GET_FAV_PROJECTS, GET_PROJECTS]}
        />
      )}
    </Grid>
  );
};

export default Home;
