import { createContext } from 'react';

export interface UserContextProps {
  id: string;
  email: string;
  phonenumber: string;
  name: string;
  role: string;
  allowedProjectIds: string;
  allowedMeasuringPoints: string;
  updateUser: () => void;
}

export const UserContext = createContext<UserContextProps | undefined>(
  undefined
);
UserContext.displayName = 'UserContext';
